<template>
  <div class="faq faq-item">
    <div
      @click="$emit('click')"
      :class="open ? 'opened' : 'closed'"
      class="click-place d-flex align-items-center"
    >
      <div class="subtitle">{{ title }}</div>
      <AIcon name="remove" width="25" height="26" class="ml-auto" />
    </div>
    <transition v-on:enter="enter" v-on:leave="enter">
      <div class="answer" v-html="html" v-show="open">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import Velocity from "velocity-animate";

export default {
  name: "faq-item",
  props: {
    title: String,
    html: undefined,
    open: Boolean,
    id: [Number, String]
  },
  data() {
    return {
      height: 0
    };
  },
  computed: {
    computedHeightAni() {
      return this.open ? this.computedHeight : 0;
    },
    computedHeight: {
      get() {
        return this.height;
      },
      set(h) {
        this.height = h;
      }
    }
  },
  mounted() {
    this.init();
    window.addEventListener("resize", this.init);
  },

  methods: {
    init() {
      const el = this.$el.querySelector(".answer");

      el.style.position = "absolute";
      el.style.visibility = "hidden";
      el.style.display = "block";

      this.computedHeight = getComputedStyle(el).height;
      el.style.position = null;
      el.style.visibility = null;
      el.style.display = "none";
    },
    enter(el, done) {
      el.style.overflow = "hidden";
      el.style.height = 0;
      Velocity(
        el,
        { opacity: this.open ? 1 : 0, height: this.computedHeightAni },
        { complete: done, duration: 300 }
      );
    }
  }
};
</script>

<style scoped lang="scss">
.faq {
  &-item {
    margin-bottom: 1.5625rem;
    border-bottom: 1px solid var(--th-gray);
    .click-place {
      cursor: pointer;
      user-select: none;
    }

    .subtitle {
      margin-bottom: 15px;
      width: calc(100% - 30px);

      & ~ svg {
        margin-bottom: 15px;
        transform: rotate(-45deg);
        transition: 300ms all;
      }
    }

    .opened {
      svg {
        transform: rotate(0deg);
      }
    }
  }
}
</style>
