<template>
  <div class="a-breadcrumb" v-if="crumbs.length">
    <ul>
      <li
        :key="k"
        v-for="(item, k) in crumbs"
        :class="[crumbs.length - 1 === k ? 'last-item' : 'item']"
      >
        <router-link
          class="color-gray"
          v-if="crumbs.length - 1 !== k"
          v-bind="Object.assign({}, item)"
        >
          {{ item.text }}
        </router-link>
        <span v-else>{{ item.text || $t("loading_title") }}</span>
        <AIcon
          icon-stroke="var(--th-gray)"
          v-if="crumbs.length - 1 !== k"
          width="18"
          height="14"
          name="arrowRightSc"
        />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "a-breadcrumb",
  beforeCreate() {
    this.$options.computed.value = function() {
      if (this.$route.meta.bcGetter) {
        return this.$store.getters[this.$route.meta.bcGetter];
      } else {
        return null;
      }
    };
  },
  computed: {
    formattedValue() {
      return this.$route.meta.bcLinkText(this.value) || null;
    },
    crumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      return pathArray.reduce((breadcrumbArray, path, idx) => {
        if (
          this.$route.matched[idx] &&
          !this.$route.matched[idx].meta.dntShow
        ) {
          let title = this.$route.matched[idx].meta.title;
          breadcrumbArray.push({
            path: path,
            to: this.$route.matched[idx],
            text: this.$route.matched[idx].meta.bcDynamic
              ? this.formattedValue
              : this.$te(title)
              ? this.$t(title)
              : title || path
          });
        }
        return breadcrumbArray;
      }, []);
    }
  }
};
</script>

<style scoped lang="scss">
.a-breadcrumb {
  margin-bottom: 44px;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style-type: none;
    li {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      svg {
        margin: 0 30px;
      }
      &:last-child {
        span {
          color: var(--th-orange);
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }
  }
}
</style>
