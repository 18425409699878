import blog from "./blog.route";
import StaticPageLayout from "@/containers/StaticPage/StaticPageLayout";
import polygon from "@/views/pages/sys/polygon";
import Company from "@/views/pages/Company";
import Faq from "@/views/pages/Faq";

export default {
  path: "/",
  component: StaticPageLayout,
  meta: { title: "main", dntShow: true },
  children: [
    {
      path: "polygon",
      name: "polygon",
      component: polygon,
      meta: { title: "polygon" }
    },
    {
      path: "company",
      name: "company",
      component: Company,
      meta: { title: "about_company" }
    },
    {
      path: "faq",
      name: "faq",
      component: Faq,
      meta: { title: "faq" }
    },
    blog
  ]
};
