<template>
  <div class="static-page article-detail-page">
    <DetailPagePreloader v-if="loading" />
    <transition name="fade">
      <div class="content ck-content" v-if="!loading">
        <h2 class="bold color-blue-dark">
          {{ title }}
        </h2>
        <div class="d-flex align-items-center date-ath">
          <articles-author
            :src="`/demo-assets/images/${cAuthor.key}.jpg`"
            :label="cAuthor.name"
            class="mb-2"
          />
          <p class="paraq-1 ml-auto">Опубликовано: {{ date }}</p>
        </div>
        <slot>
          <div v-html="content" />
        </slot>
        <slot name="footer" />
      </div>
    </transition>
    <h3 class="bold">{{ $t("recently_added_articles") }}</h3>
    <div class="slider-wrapper">
      <VueSlickCarousel
        ref="reviews"
        v-bind="slickSettings"
        v-if="Array.isArray(cBlogList.results)"
      >
        <articleHomePageItem
          min-height="250"
          :key="key"
          v-for="(post, key) in cBlogList.results || []"
          :title="post.title"
          :preview="$base_media_url + '/' + post.main_image"
          :to="{
            path: `/blog/catalog/${post.id}`,
            params: { title: `post ${post.id}` }
          }"
        >
          {{ post.short_description }}
        </articleHomePageItem>

        <template #prevArrow>
          <div class="custom-arrow">
            <AIcon width="32" height="32" name="angel-right" />
          </div>
        </template>
        <template #nextArrow>
          <div class="custom-arrow">
            <AIcon width="32" height="32" name="angel-right" />
          </div>
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import articleHomePageItem from "@/components/articles/articles-homepage-item";
import articlesAuthor from "@/components/articles/article-author";

export default {
  name: "articles-detail",
  props: {
    title: String,
    loading: Boolean,
    content: undefined,
    date: String,
    author: [Array, Object, String]
  },
  components: { VueSlickCarousel, articleHomePageItem, articlesAuthor },
  data() {
    return {
      slickSettings: {
        dots: true,
        dotsClass: "slick-dots reviews-dots",
        edgeFriction: 0.35,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 7000
      }
    };
  },
  computed: {
    cBlogList() {
      return this.$sf.get("BLOG_LIST") || {};
    },
    cAuthor() {
      return this.author || {};
    }
  },
  async mounted() {
    this.$sf.set("SET_BLOG_LIST");
    await this.$sf.act("LOAD_BLOG_LIST", { page_size: 10 });
  }
};
</script>

<style scoped lang="scss">
.slider-wrapper {
  margin: -50px -22px;

  &::v-deep .slick-slider {
    .slick-track {
      display: flex;
      align-items: stretch;
    }

    .slick-list {
      padding: 50px 0;
    }

    .reviews-dots {
      li {
        button {
          width: 20px;
          height: 20px;

          &:before {
            transition: all 300ms;
            border-radius: 10em;
            background: var(--th-gray);
            opacity: 1;
            content: "";
          }
        }

        border-radius: 10em;
      }

      .slick-active {
        button {
          &:before {
            background: var(--th-orange);
          }
        }
      }
    }

    .custom-arrow {
      width: 32px;
      height: 32px;

      &:after,
      &:before {
        display: none;
      }

      &.slick-prev {
        left: -68px;

        svg {
          transform: rotate(180deg);
        }
      }

      &.slick-next {
        right: -68px;
      }
    }
  }

  &::v-deep .slick-slide {
    padding: 0 20px;
    height: auto;

    & > div {
      height: 100%;
    }

    .reviews-item {
      height: 100%;
      border-radius: 15px;
      padding: 30px;
      background: #ffffff;
      box-shadow: 1px 8px 80px rgba(0, 0, 0, 0.05);

      .date {
        font-size: 0.75rem;
      }
    }
  }
}
.article-detail-page {
  h2 {
    margin-bottom: 20px;
  }
  h3 {
    color: var(--th-blue-dark);
    margin-bottom: 40px;
  }
  .content {
    margin-bottom: 60px;
  }
  .date-ath {
    margin-bottom: 30px;
    &::v-deep {
      .article-author {
        display: none;
        .v-image {
          height: 50px;
          width: 50px;
        }
        &__label {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1rem;
          color: var(--th-gray);
        }
      }
    }
  }
}
</style>
