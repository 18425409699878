<template>
  <div class="top-place">
    <div class="top-place__container d-flex align-items-center">
      <div class="ml-auto">
        <language-switcher class="ml-5" v-slot="{ links }">
          <router-link
            v-for="link in links"
            :key="link.langIndex"
            :to="link.url"
          >
            <h5>{{ link.langName }}</h5>
          </router-link>
        </language-switcher>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "simplePanelHeader"
};
</script>

<style scoped lang="scss">
.top-place {
  background: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  &__container {
    padding: 30px 0;
    min-height: 128px;
    max-height: 128px;
  }
}
</style>
