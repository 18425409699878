import storeFacade from "@/utils/facade/store/store.facade";

export function getToken() {
  return storeFacade.get("TOKEN");
}

export function setToken(token) {
  return storeFacade.set("SET_TOKEN", token);
}

export function removeToken() {
  return storeFacade.set("SET_TOKEN", undefined);
}

export function getBigToken() {
  return storeFacade.get("BIG_TOKEN");
}

export function setBigToken(token) {
  return storeFacade.set("SET_BIG_TOKEN", token);
}

export function removeBigToken() {
  return storeFacade.set("SET_BIG_TOKEN", undefined);
}
