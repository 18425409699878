<template>
  <div class="panel-layout d-flex align-items-stretch">
    <aside-panel />
    <div class="panel-layout__content">
      <simple-panel-header />
      <div class="panel-layout__content-view">
        <router-view
          ref="content"
          :search-layout.sync="searchLayout"
          :key="this.$route.path"
        ></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import ContainersData from "../containers-data";
import ContainersProps from "../containers-props";
import ContainersComputed from "../containers-computed";

import simplePanelHeader from "@/containers/simple-panel/simplePanelHeader";
import asidePanel from "@/containers/simple-panel/templates/aside/asidePanel";
export default {
  name: "simplePanelLayout",
  mixins: [ContainersData, ContainersProps, ContainersComputed],
  components: {
    simplePanelHeader,
    asidePanel
  }
};
</script>

<style scoped lang="scss">
.panel-layout {
  min-height: 100vh;
  &__content {
    background: #fff;
    width: 100%;
    max-height: 100vh;
    &-view {
      padding: 45px 30px;
      max-height: calc(100% - 128px);
      overflow-y: auto;
    }
  }
  .panel-button {
    padding: 0 35px;
  }
}
</style>
