<template>
  <div class="profile container">
    <div class="profile-title">
      <h2>{{ $t("my_profile") }}</h2>
    </div>
    <div class="d-flex">
      <div class="profile-photo">
        <user-photo />
        <div class="replace-photo">
          <label for="replace-photo">Заменить фото</label>
          <input class="replace-photo-input" id="replace-photo" type="file" />
        </div>
      </div>
      <div class="profile-form col-md-6">
        <AForm>
          <AInput :label="$t('name_or_company_name')" v-model="cTest.name" />
          <AInput :label="$t('number_phone')" v-model="cTest.phone"/>
          <ADropDown :label="$t('city')" prependIcon="location" />
          <AButton
            @click.prevent="saveDataProfile"
            class="mt-4 w-100 text-uppercase"
          >
            {{ $t("save") }}
          </AButton>
        </AForm>
      </div>
    </div>
  </div>
</template>

<script>
import UserPhoto from "@/components/UserPhoto/UserPhoto";

export default {
  name: "Profile",
  data() {
    return {
      test: {
        name: ""
      }
    };
  },
  computed: {
    cTest: {
      get: function() {
        return this.$sf.get("USER_DATA");
      },
      set: function(val) {
        console.log(val)
        this.test = val;
      }
    }
  },
  methods: {
    saveDataProfile() {
      console.log("Save");
      this.cTest = "asdas";
    }
  },
  components: {
    UserPhoto
  }
};
</script>

<style scoped lang="scss">
.profile {
  padding: 35px;
  margin-top: 45px;
  background-color: #fff;
  box-shadow: 0 0 28px rgb(0 0 0 / 20%);
  border-radius: 15px;
  > .profile-title {
    margin-bottom: 35px;
    > h2 {
      font-weight: 600;
    }
  }
  .profile-photo {
    position: relative;
    padding-right: 65px;
    &::before {
      position: absolute;
      content: "";
      width: 1px;
      background-color: var(--th-very-light-gray);
      height: 100%;
      right: 0;
    }
  }
  .profile-form {
    padding-left: 65px;
  }
  .replace-photo {
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--th-blue-dark);
    margin-top: 10px;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    > label {
      cursor: pointer;
    }
  }
  .replace-photo-input {
    display: none;
  }
}
</style>
