const modulesFiles = require.context("@/store", true, /\.const\.js/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath);
  Object.entries(value.default).forEach(value => {
    modules[value[0]] = value[1];
  });
  return modules;
}, {});

export default modules;
