import mutations from "@/store/modules/articles/list/_list.mutations";
import states from "@/store/modules/articles/list/_list.states";
import getters from "@/store/modules/articles/list/_list.getters";
import actions from "@/store/modules/articles/list/_list.actions";

export default {
  ...states,
  ...getters,
  ...mutations,
  ...actions
};
