<template>
  <div class="home-page">
    <section id="first">
      <common-header />
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h3 class="bold" v-html="$t('home_page_main_title')" />
          </div>
          <div class="col-md-6">
            <div class="d-flex w-100 justify-content-md-end">
              <div class="home-page-plane">
                <h4 class="bold">{{ $t("lawyers_work_with_us") }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <AForm>
              <ATextarea
                :invalid-feedback="cError.note"
                v-model="requestData.note"
                :label="$t('description')"
                :placeholder="$t('describe_the_task')"
                rows="5"
                required
              />
              <AImagePick
                upload-url="https://zan.bar/media/temp"
                @uploaded="addRequest"
                :uploaded-files.sync="requestData.photos"
                :upload-start="startUploadFile"
                :sub-description="`(${$t('not-required')})`"
              />
              <ADropDown
                required
                :label="$t('select_country')"
                prependIcon="location"
                :value.sync="requestData.country_id"
                :invalid-feedback="
                  cError.city_id ? $t('you-need-to-select-a-country') : false
                "
                :options="cCountry"
              />
              <ADropDown
                required
                :label="$t('select_region')"
                prependIcon="location"
                :value.sync="requestData.city_id"
                :invalid-feedback="
                  cError.city_id ? $t('you-need-to-select-a-region') : false
                "
                :options="cCity"
              />
              <ADropDown
                :invalid-feedback="
                  cError.ad_category_id
                    ? $t('you-need-to-select-a-category')
                    : false
                "
                :button-text="$t('select_case_category')"
                prependIcon="box"
                :value.sync="requestData.ad_category_id"
                required
                :options="cCategory"
              />
              <AButton
                @click.prevent="checkRequest"
                class="mt-4 w-100 text-uppercase"
                :loading="this.startUploadFile"
              >
                {{ $t("submit_application") }}
              </AButton>
            </AForm>
          </div>
        </div>
      </div>
    </section>
    <section id="two">
      <div class="container">
        <h3 class="bold text-center">
          {{ $t("home_page_main_title_two_section", { b: "ZAN.BAR" }) }}
        </h3>
        <div class="row">
          <div class="col-md-4">
            <div class="homepage_stat-item">
              <AIcon
                v-show="$i18n.locale === 'ru'"
                icon-stroke="none"
                width="234"
                height="102"
                name="stat_1_ru"
              />
              <AIcon
                v-show="$i18n.locale === 'en'"
                icon-stroke="none"
                width="234"
                height="102"
                name="stat_1_en"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="homepage_stat-item">
              <AIcon
                v-show="$i18n.locale === 'ru'"
                icon-stroke="none"
                width="231"
                height="117"
                name="stat_2_ru"
              />
              <AIcon
                v-show="$i18n.locale === 'en'"
                icon-stroke="none"
                width="231"
                height="117"
                name="stat_2_en"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="homepage_stat-item">
              <AIcon
                v-show="$i18n.locale === 'ru'"
                icon-stroke="none"
                width="234"
                height="137"
                name="stat_3_ru"
              />
              <AIcon
                v-show="$i18n.locale === 'en'"
                icon-stroke="none"
                width="234"
                height="137"
                name="stat_3_en"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="three">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h3 class="bold" v-html="$t('home_page_scanQr')" />
            <div class="d-flex download-app">
              <div class="icons">
                <a target="_blank" href="#">
                  <AIcon
                    icon-stroke="none"
                    width="60"
                    height="60"
                    name="f-apple-store"
                  />
                </a>
                <a target="_blank" href="#">
                  <AIcon
                    icon-stroke="none"
                    width="60"
                    height="60"
                    name="f-android-store"
                  />
                </a>
              </div>
              <div class="qr">
                <AIcon
                  icon-stroke="none"
                  icon-fill="var(--th-blue-dark)"
                  name="qr-go-to-download"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="phones-images">
              <img src="@/assets/images/phones.png" alt="phones" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="fourth">
      <div class="container">
        <h3 class="bold text-center" v-html="$t('how_it_works')" />
        <div class="row">
          <div class="col-md-5 offset-md-1">
            <div
              class="d-flex flex-column align-items-center justify-content-end h-100"
            >
              <AIcon
                icon-stroke="none"
                width="313"
                height="300"
                name="how-it-works"
              />
              <h4
                class="bold color-blue text-center"
                v-html="$t('add_application')"
              />
            </div>
          </div>
          <div class="col-md-5">
            <div
              class="d-flex flex-column align-items-center justify-content-end h-100"
            >
              <AIcon
                icon-stroke="none"
                width="321"
                height="257"
                name="how-it-works2"
              />
              <h4
                class="bold color-blue text-center"
                v-html="$t('receive_offers')"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="five">
      <div class="container">
        <h3 class="text-center bold">{{ $t("reviews") }}</h3>
        <div class="slider-wrapper">
          <VueSlickCarousel ref="reviews" v-bind="slickSettings">
            <div :key="key" v-for="(item, key) in reviews" class="reviews-item">
              <div class="reviews-item__head">
                <div class="d-flex align-items-center mb-2">
                  <h4 class="color-black bold m-0 title">
                    {{ item[`name_${$i18n.locale}`] }}
                  </h4>
                  <rating class="ml-auto" :amount="item.rating" />
                </div>
              </div>
              <div class="reviews-item__body">
                <div class="date paraq-2 color-gray mb-3">{{ item.date }}</div>
                <p v-html="item[`body_${$i18n.locale}`]" />
              </div>
            </div>
            <template #prevArrow>
              <div class="custom-arrow">
                <AIcon width="32" height="32" name="angel-right" />
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-arrow">
                <AIcon width="32" height="32" name="angel-right" />
              </div>
            </template>
          </VueSlickCarousel>
        </div>
      </div>
    </section>
    <section id="six">
      <div class="container">
        <div
          class="w-100 d-flex align-items-end justify-content-between articles-teaser"
        >
          <h3 class="bold m-0">{{ $t("useful_blog_posts") }}</h3>
          <ALink
            to="/blog"
            class="head-link mb-1 text-uppercase d-flex align-items-center"
          >
            <span class="mr-3">{{ $t("see_all") }}</span>
            <AIcon name="arrowRightSc" />
          </ALink>
        </div>
        <div class="row">
          <div
            :class="{
              'col-md-7': [0, 3].includes(key),
              'col-md-5': [1, 2].includes(key)
            }"
            :key="key"
            v-for="(post, key) in cBlogList.results"
          >
            <articleHomePageItem
              :title="post.title"
              :preview="$base_media_url + '/' + post.main_image"
              :to="{
                path: `/blog/catalog/${post.id}`,
                params: { title: `post ${post.id}` }
              }"
            >
              {{ post.short_description }}
            </articleHomePageItem>
          </div>
        </div>
      </div>
    </section>

    <AModal :show.sync="userModal" size="sm">
      <div v-show="!userCheck.phone" class="input-phone-name">
        <AForm @submit.prevent="submitUserForm">
          <h4 class="bold text-center mb-40 color-blue-dark">
            {{ $t("to_create_an_application_you_need_to_login") }}
          </h4>
          <AInput
            v-model="userData.name"
            :invalid-feedback="cError.name"
            :label="$t('name')"
            :placeholder="$t('name')"
          />
          <AInput
            :label="$t('your_phone_number')"
            v-model="userData.phone"
            :invalid-feedback="cError.phone"
          >
            <template #append-content>
              <a-dropdown-list
                @a-dropdown-selected="$sf.set('SET_USER_COUNTRY', $event)"
                :a-dropdown-selected="$sf.get('USER_COUNTRY')"
                position="center"
                class="phone-select"
              >
                <template #target-content>
                  <div class="d-flex align-items-center">
                    <AImage
                      v-if="cUserCountry.img"
                      :src="`${$base_media_url}/${cUserCountry.img}`"
                      :alt="cUserCountry.name || cUserCountry.img"
                    />
                    {{ cUserCountry.phone_code }}
                  </div>
                </template>
                <icon-text-option
                  :key="key"
                  v-for="(country, key) in $sf.get(
                    'CATALOG_AC',
                    'GET_COUNTRIES'
                  )"
                  :data="{ country }"
                  :active="$sf.get('USER_COUNTRY')"
                >
                  {{ country.phone_code }}
                </icon-text-option>
              </a-dropdown-list>
            </template>
          </AInput>
          <AButton
            :loading="userCheck.loading"
            class="w-100 mt-40 text-uppercase"
            >
            {{ $t('to_get_the_code') }}
          </AButton>
        </AForm>
      </div>
      <div v-show="userCheck.phone" class="input-sms-code">
        <AForm @submit.prevent="regUser">
          <h4 class="bold text-center mb-40 color-blue-dark">
            {{ $t("to_create_an_application_you_need_to_login") }}
          </h4>
          <p class="paraq-3 text-center mb-2">
            {{ $t("we_sent_an_sms_with_a_code_to_the_number") }}
          </p>
          <p class="paraq-3 text-center m-0 color-blue">
            {{ userData.phone || "none" }}
          </p>
          <AInput
            :disabled="regUserState"
            v-model="userData.sms_code"
            class="mt-40"
            :label="$t('code_from_sms')"
            placeholder="****"
            :invalid-feedback="cError.sms_code"
          />
          <AButton :loading="regUserState" class="w-100 mt-40 text-uppercase">
            {{ $t("send") }}
          </AButton>
        </AForm>
      </div>
    </AModal>
    <AModal :show.sync="cRequest.modal" size="sm">
      <div v-show="cRequest.success">
        <h4 class="bold text-center mb-40 color-blue-dark" v-html="$t('application_is_accepted')"/>
        <p class="paraq">
          {{ $t("mobile_version_of_the_application") }}
        </p>
        <p class="paraq-2 text-uppercase color-orange">
          {{ $t("we_are_glad_to_help") }}
        </p>
      </div>
      <div v-show="!cRequest.success">
        <h4
          v-if="!cRequest.message"
          class="bold text-center mb-40 color-orange"
        >
          Ваша заявка отклонена
        </h4>
        <p class="paraq" v-if="!cRequest.message">
          К сожалению, ваша заявка отклонена, так как у вас уже есть похожее
          объявление
        </p>
        <p class="paraq" v-else>
          {{ cRequest.message }}
        </p>
      </div>
    </AModal>
    <common-footer />
  </div>
</template>
<script>
import { DIC } from "@/api/dic.api";
import { CheckUserPhone, RegUser } from "@/api/user.api";
import { CreateRequest } from "@/api/request.api";
import { removeToken, setToken } from "@/utils/token";
import rating from "@/components/base/rating";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// МБ костыль а мб и нет
import commonHeader from "@/containers/commonHeader";
import commonFooter from "@/containers/commonFooter";
import articleHomePageItem from "@/components/articles/articles-homepage-item";
import CommonHeader from "@/containers/commonHeader";

export default {
  name: "Home",
  data() {
    return {
      phoneCode: 1,
      reviews: [
        {
          rating: 5,
          name_ru: "Олег Рыбалкин",
          name_en: "Oleg Rybalkin",
          body_ru:
            "Вступать в наследство оказалось не так просто, как я думал. Казалось бы, дачный участок с обычным домиком, а сил пришлось потратить море. Все стоило очень недорого. Юрист из сервиса Zan.Bar качественно оформил необходимые документы, после этого я оформил участок",
          body_en:
            "Getting into the inheritance wasn't as easy as I thought. It would seem that it is only a plot of land with an ordinary house, but I had to spend a lot of energy. Everything was not so expensive. The lawyer from Zan.Bar qualitatively issued the necessary documents, after that I issued the plot of land",
          date: "20.12.2020"
        },
        {
          rating: 5,
          name_ru: "Толегенова Асема Жолдаскызы",
          name_en: "Tolegenova Asema Zholdaskyzy",
          body_ru:
            "Теперь я знаю, где работают самые классные юристы! В юридическом сервисе Zan.Bar! Мне понадобилась помощь в решение вопросов по защите прав потребителей. Они помогли оформить заявление с жалобой на поставщика некачественного товара, который в итоге заменил мне партию, да еще и компенсировал ущерб. Спасибо, без вас бы не справилась!",
          body_en:
            "Now I know where the best lawyers work! In Zan.Bar service! I needed help with consumer protection issues. They helped to file a complaint against the supplier of low-quality goods. Eventually he replaced my batch, and even compensated for the damage. Thank you, I couldn't have done it without you!",
          date: "18.01.2021"
        },
        {
          rating: 5,
          name_ru: "Шарипова Айман",
          name_en: "Sharipova Aiman",
          body_ru:
            "После серьезного ДТП на меня свалилась тонна проблем. Все помогли решить юристы по ДТП из сервиса Zan.Bar. В итоге на меня не завели уголовное дело, денежные расходы также были сведены к минимуму. Советую обращаться именно к ним, там очень грамотные люди работают. ",
          body_en:
            'After a serious accident, I had a lot of problems. Lawyers from Zan.Bar service" helped to solve all of them. As a result, I was not prosecuted, and my financial expenses were also kept to a minimum. I advise you to contact them, there are very competent people working there.',
          date: "22.01.2021"
        },
        {
          rating: 5,
          name_ru: "Байсеитов Аблай ",
          name_en: "Baiseitov Ablay",
          body_ru:
            "Мне помог оформить сделку с недвижимостью на выгодных условиях юрист сервиса Zan.Bar. До этого обращался к другому специалисту, который за консультацию содрал с меня кучу денег, но так дело и не продвинулось. А здесь мне почти за аналогичную сумму оформили все документы и помогли приобрести торговые площади для бизнеса. Спасибо вам за профессионализм!",
          body_en:
            "The lawyer of Zan.Bar service helped me to arrange a real estate transaction on favorable terms. Before that, I went to another specialist, who charged me a lot of money for consultation, but the case has not progressed. And here, for almost the same amount of money, I was issued all the documents and helped to purchase retail space for business. Thank you for your professionalism!",
          date: "22.01.2021"
        }
      ],
      slickSettings: {
        dots: true,
        dotsClass: "slick-dots reviews-dots",
        edgeFriction: 0.35,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 7000
      },
      userModal: false,
      errors: {},
      dic: {},
      regUserState: false,
      startUploadFile: false,
      loadingImages: false,
      request: {
        success: false,
        alertMessage: null,
        modal: false
      },
      userCheck: {
        loading: false,
        phone: false,
        sms_sent: null
      },
      userData: {
        phone: null,
        name: null,
        sms_code: null
      },
      requestData: {
        kind: 2,
        ad_category_id: null,
        city_id: null,
        country_id: null,
        note: null,
        photos: []
      }
    };
  },
  components: {
    CommonHeader,
    VueSlickCarousel,
    rating,
    articleHomePageItem,
    commonHeader,
    commonFooter
  },
  watch: {
    "requestData.country_id"() {
      this.requestData.city_id = null;
    },
    userModal(val) {
      if (!val) {
        this.userData.valuesToNull();
        this.userCheck.valuesToNull();
      }
    },
    async "$i18n.locale"() {
      await DIC().then(resp => (this.dic = resp));
      await this.$sf.act("LOAD_BLOG_LIST", { page_size: 4 });
    }
  },
  computed: {
    cUserCountry() {
      return this.$sf.get("USER_COUNTRY");
    },
    cCountryCode() {
      return this.$sf.get("CATALOG_AC", "GET_COUNTRIES");
    },
    cBlogList() {
      return this.$sf.get("BLOG_LIST") || {};
    },
    cRequest: {
      get() {
        return this.request;
      },
      set(val) {
        this.request = val;
      }
    },
    cError: {
      get() {
        return this.errors;
      },
      set(val) {
        this.errors = val;
      }
    },
    cCategory() {
      return this.dic.data?.ad_categories.convertToOption("id", "name") || [];
    },
    cCity() {
      return (
        this.dic.data?.cities
          .filter(c => c.country_id === this.requestData.country_id)
          .convertToOption("id", "name") || []
      );
    },
    cCountry() {
      return this.dic.data?.countries.convertToOption("id", "name") || [];
    }
  },
  async mounted() {
    await this.$sf.act("LOAD_BLOG_LIST", { page_size: 4 });
    DIC().then(resp => (this.dic = resp));
  },
  methods: {
    validateRequestForm(callback) {
      this.errors = {};
      // eslint-disable-next-line no-unused-vars
      for (let [k, v] of Object.entries(this.requestData)) {
        if (!v) {
          this.errors[k] = this.$t("should_not_be_empty");
        } else {
          if (k === "note" && v.length <= 20)
            this.errors[k] = this.$t("incorrect_description_format");
        }
      }
      if (Object.keys(this.errors).length === 0) callback();
    },
    validateUserForm(callback) {
      this.errors = {};
      // eslint-disable-next-line no-unused-vars
      for (let [k, v] of Object.entries(this.userData)) {
        if (["sms_code", "usr_id"].includes(k)) continue;
        if (!v) {
          this.errors[k] = this.$t("should_not_be_empty");
        } else {
          if (k === "phone" && !/[0-9]/.test(v))
            this.errors[k] = this.$t("incorrect_phone_number_entered");
        }
      }
      if (Object.keys(this.errors).length === 0) callback();
    },
    // eslint-disable-next-line no-unused-vars
    checkRequest(e) {
      this.validateRequestForm(() => {
        this.userModal = true;
      });
    },
    async submitUserForm() {
      this.validateUserForm(async () => {
        this.userCheck.loading = true;
        await CheckUserPhone({
          phone: (this.$sf.get("USER_COUNTRY").phone_code + this.userData.phone).replace(/[() +\-\s]/g, "")
        }).then(
          () => {
            this.userCheck.phone = true;
          },
          err => {
            this.cError.message_error = err?.error_desc;
          }
        );
        this.userCheck.loading = false;
      });
    },
    async addRequest() {
      await CreateRequest(this.requestData).then(
        res => {
          console.error(res);
          this.request = {
            modal: true,
            success: true
          };
        },
        error => {
          if (error?.error_code !== "ad_duplicated") {
            this.request = {
              modal: true,
              success: false,
              message: error?.error_desc || false
            };
          } else {
            this.request = {
              modal: true,
              success: false
            };
          }
        }
      );
      removeToken();
      this.startUploadFile = false;
      this.requestData = {
        kind: 2,
        ad_category_id: null,
        city_id: null,
        note: null,
        photos: []
      };
    },
    async regUser() {
      let params = {
        name: this.userData.name,
        sms_code: +this.userData.sms_code,
        phone: this.userData.phone.replace(/[() +\-\s]/g, ""),
        city_id: this.requestData.city_id
      };
      this.errors = {};
      await RegUser(params).then(
        async res => {
          setToken(res.small_token);
          this.userModal = false;
          this.startUploadFile = true;
          this.requestData.usr_id = res.id;
          this.regUserState = false;
        },
        err => {
          // if (err.error_code === "wrong_sms_code")
          this.cError.sms_code = err?.error_desc;
          // else this.cError.message_error = err.error_desc
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
section {
  background: var(--th-background);
}

.download-app {
  .icons {
    margin-right: 35px;

    a {
      display: block;
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .qr {
    padding: 5px;
    box-shadow: 1px 8px 80px rgba(0, 0, 0, 0.05);
    background: #ffffff;
    border-radius: 15px;
    width: 230px;
    height: 230px;

    &::v-deep {
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

h3 {
  margin-bottom: 2.4375rem;
  color: var(--th-blue-dark);

  &::v-deep span {
    color: var(--th-orange);
  }
}

.stores {
  margin-bottom: 100px;

  p.paraq-italic {
    margin-right: 0;
  }

  &__item {
    margin-right: 15px;
    margin-bottom: 10px;
  }
}

#first {
  background: url("~@/assets/images/background.jpg") center bottom / cover;
  padding-bottom: 180px;
}

#two {
  padding-bottom: 130px;

  .homepage_stat-item {
    background: #fff;
    border-radius: 15px;
    padding: 30px 63px;
    box-shadow: 1px 8px 80px rgba(0, 0, 0, 0.05);
    height: 100%;
    display: flex;
    align-items: center;
  }

  h3 {
    margin-bottom: 85px;
  }
}

#three {
  padding: 64px 0;

  .phones-images {
    position: relative;

    img {
      filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.15));
      transform: rotate(2.97deg);
      margin-top: -90px;
      width: 100%;
    }
  }
}

#fourth {
  padding: 85px 0 70px;

  h3 {
    margin-bottom: 4.6875rem;
  }

  h4 {
    margin-top: 32px;
  }
}

#five {
  padding: 85px 0 77px;

  h3 {
    margin-bottom: 85px;
  }

  .slider-wrapper {
    width: calc(100% - 119px);
    margin: -50px auto;

    &::v-deep .slick-slider {
      .slick-track {
        display: flex;
        align-items: stretch;
      }

      .slick-list {
        padding: 50px 0;
      }

      .reviews-dots {
        li {
          button {
            width: 20px;
            height: 20px;

            &:before {
              transition: all 300ms;
              border-radius: 10em;
              background: var(--th-gray);
              opacity: 1;
              content: "";
            }
          }

          border-radius: 10em;
        }

        .slick-active {
          button {
            &:before {
              background: var(--th-orange);
            }
          }
        }
      }

      .custom-arrow {
        width: 32px;
        height: 32px;

        &:after,
        &:before {
          display: none;
        }

        &.slick-prev {
          left: -68px;

          svg {
            transform: rotate(180deg);
          }
        }

        &.slick-next {
          right: -68px;
        }
      }
    }

    &::v-deep .slick-slide {
      padding: 0 20px;
      height: auto;

      & > div {
        height: 100%;
      }

      .reviews-item {
        height: 100%;
        border-radius: 15px;
        padding: 30px;
        background: #ffffff;
        box-shadow: 1px 8px 80px rgba(0, 0, 0, 0.05);

        .date {
          font-size: 0.75rem;
        }
      }
    }
  }
}

.articles-teaser {
  margin-bottom: 2.5rem;
}
.home-page-plane {
  padding: 5px 10px;
  background: var(--th-blue);
  border-radius: 5px;
  h4 {
    color: var(--th-white);
    margin: 0;
  }
}
</style>
