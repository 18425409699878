// actions

// mutations
export const SET_TYPE_PANEL = "_user.getters/SET_TYPE_PANEL";

// getter
export const TYPE_PANEL = "_user.getters/TYPE_PANEL";

export default {
  TYPE_PANEL,
  SET_TYPE_PANEL
};
