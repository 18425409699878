<template>
  <div class="articles articles-list row">
    <slot />
  </div>
</template>

<script>
export default {
  name: "articles-list"
};
</script>

<style scoped lang="scss">
.articles {
  &-list {
    > * {}
  }
}
</style>
