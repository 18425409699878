import {
  SET_BLOG_LIST,
  SET_LOADING_STATE_BLOG
} from "@/store/modules/articles/list/_list.const";
export default {
  mutations: {
    [SET_BLOG_LIST](state, payload) {
      state.blog.list = payload;
    },
    [SET_LOADING_STATE_BLOG](state, payload) {
      state.blog.loading = payload;
    }
  }
};
