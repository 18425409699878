<template>
  <ALink class="article__link" :to="to">
    <div class="article" :style="{ 'min-height': `${minHeight}px` }">
      <AImage :src="cPreview" :alt="title" />
      <AIcon
        class="article__logotype"
        icon-stroke="none"
        width="64"
        height="64"
        name="zb-logotype-without-text"
      />
      <div class="article__item">
        <h4 class="article__item-title">{{ title }}</h4>
        <p
          ref="description"
          class="article__item-description"
          v-text="cDescription"
        />
      </div>
    </div>
  </ALink>
</template>

<script>
export default {
  name: "articles-homepage-item",
  props: {
    preview: String,
    title: [String, Number],
    description: String,
    to: [String, Object],
    minHeight: [String, Number]
  },
  data() {
    return {
      mDescription: "",
      mDescriptionLimit: 100,
      mDescriptionAm: 14.9375
    };
  },
  watch: {
    mDescriptionAm() {
      this.calculateCount();
    }
  },
  mounted() {
    this.calculateCount();
    window.addEventListener("resize", this.calculateCount);
  },
  updated() {
    this.calculateCount();
  },
  computed: {
    cPreview() {
      return this.preview;
    },
    cDescription() {
      return this.mDescription.slice(0, this.mDescriptionLimit).trim() + " ...";
    }
  },
  methods: {
    calculateCount() {
      this.mDescription = this.description || this.$slots.default[0].text;
      const w = this.$el?.offsetWidth;
      this.mDescriptionLimit = Math.floor((w / this.mDescriptionAm) * 2 - 4);
    }
  }
};
</script>

<style scoped lang="scss">
.article {
  position: relative;
  min-height: 325px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  &__logotype {
    position: absolute;
    top: 16px;
    left: 16px;
  }
  &::v-deep {
    .v-image {
      width: 100%;
      height: 100%;
      position: absolute;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      svg {
        width: 50px;
        height: 50px;
      }

      .img--none {
        background: #e2e2e2;
        padding: 15px;
        height: 100%;
      }
    }
  }

  &__item {
    width: 100%;
    position: relative;
    margin-top: auto;
    z-index: 3;
    padding: 20px 25px;
    transition: all 200ms;
    transform: translateY(90px);

    &-title {
      color: #ffffff;
      position: relative;
      z-index: 4;
      margin-bottom: 15px;
      min-height: 64px;
    }

    &-description {
      color: #ffffff;
      position: relative;
      z-index: 4;
      font-size: 22px;
      line-height: 30px;
      font-weight: 400;
      min-height: 50px;
    }

    &:after {
      content: "";
      position: absolute;
      top: calc(-100% - 90px);
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) calc(0% - 90px),
        rgba(0, 0, 0, 0.08) calc(46.35% - 90px),
        rgba(0, 0, 0, 0.51) calc(78.53% - 90px),
        rgba(0, 0, 0, 0.9) calc(100% - 90px)
      );
    }
  }
  &__link {
    display: block;
    text-decoration: none;
    margin-bottom: 30px;
    &:hover {
      [class$="__item"]{
        transform: translateY(0);
      }
    }
  }
}
</style>
