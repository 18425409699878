<template>
  <div>admin</div>
</template>

<script>
import props from "@/containers/simple-panel/templates/aside/aside-props";

export default {
  name: "admin",
  props
};
</script>

<style scoped></style>
