<template>
  <div class="rating">
    <div class="rating__stars rating__stars-negative">
      <AIcon
        icon-stroke="none"
        icon-fill="var(--th-gray)"
        width="95"
        height="15"
        icon-fille="red"
        name="rating-stars"
      />
    </div>
    <div
      :style="{ maxWidth: `${cPercent}%` }"
      class="rating__stars rating__stars-positive"
    >
      <AIcon
        icon-stroke="none"
        icon-fill="var(--th-orange)"
        width="95"
        height="15"
        icon-fille="red"
        name="rating-stars"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "rating",
  props: {
    percent: {
      type: Number,
      default: 0
    },
    amount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    cPercent() {
      return this.amount ? (+this.amount * 100) / 5 : this.percent;
    }
  }
};
</script>

<style scoped lang="scss">
.rating {
  position: relative;
  height: 15px;
  width: 95px;
  &__stars {
    top: 0;
    left: 0;
    line-height: 1;
    position: absolute;
    overflow: hidden;
    &-positive {
      max-width: 60%;
    }
  }
}
</style>
