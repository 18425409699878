import Articles from "@/views/pages/Articles";
import ArticlesDetail from "@/views/pages/ArticlesDetail";

export default {
  path: "blog",
  component: {
    render: c => c("router-view")
  },
  meta: {
    title: "blog"
  },
  redirect: "blog/catalog",
  children: [
    {
      path: ":catalog",
      name: "article-catalog",
      component: Articles,
      meta: { title: "blog" }
    },
    {
      path: ":catalog/:id",
      name: "article-detail",
      component: ArticlesDetail,
      meta: {
        title: "Блог",
        bcDynamic: true,
        bcGetter: "detail_active_title",
        bcLinkText: title => title
      }
    }
  ]
};
