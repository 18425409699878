export default {
  default_title_page_t: "ZanBar - отправь заявку лучшим юристам города!",
  home_page_main_title:
    "<span>Оставьте заявку</span> и получите предложение с ценами, отзывами и рейтингами от лучших юристов вашего города",
  // homepage two section
  home_page_main_title_two_section: "%{b} в цифрах",
  home_page_scanQr: "Отсканируйте QR-код<br />и загрузите мобильное приложение",
  how_it_works: "Как это работает?",
  add_application: "Оставляете заявку в <br />Zan.Bar",
  receive_offers: "Получаете предложения <br /> от юристов вашего города",
  reviews: "Отзывы",
  main: "Главная",
  about_company: "О компании",
  blog: "Блог",
  faq: "FAQ",
  recently_added_articles: "Последние добавленные статьи",
  lawyers_work_with_us: "1300 юристов работают с нами",
  my_profile: "Мой Профиль",
  articles: "Статьи",
  editing_pages: "Редактирование страниц",
  settings: "Настройки",
  // для админки
  name_or_company_name: "Имя или название компании",
  number_phone: "Номер телефона",
  city: "Город",
  applications: "Заявки",
  to_create_an_application_you_need_to_login: "Для создания заявки необходимо авторизоваться",
  we_sent_an_sms_with_a_code_to_the_number: "Мы отправили СМС с кодом на номер",
  to_get_the_code: "Получить код",
  application_is_accepted: "Спасибо!<br /> Ваша заявка принята",
  mobile_version_of_the_application: "Для вашего удобства скачивайте мобильную версию приложения",
  we_are_glad_to_help: "Мы рады вам помочь",
  code_from_sms: "Введите код из СМС",

};
