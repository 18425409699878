import { DIC } from "@/api/dic.api";
import {
  LOAD_CATALOG,
  SET_CATALOG
} from "@/store/modules/sys/catalog/_catalog.const";

export default {
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async [LOAD_CATALOG]({ commit }, payload) {
      return await DIC(payload).then(
        resp => {
          commit(SET_CATALOG, resp);
          return resp;
        },
        error => {
          commit(SET_CATALOG, {});
          throw error;
        }
      );
    }
  }
};
