import {
  SET_BLOG_LIST,
  LOAD_BLOG_LIST,
  SET_LOADING_STATE_BLOG
} from "@/store/modules/articles/list/_list.const";

import { LoadBlogList } from "@/api/articles/_list.articles.api";

export default {
  actions: {
    // eslint-disable-next-line no-unused-vars
    async [LOAD_BLOG_LIST]({ commit, dispatch }, payload) {
      await LoadBlogList(payload).then(
        resp => {
          commit(SET_BLOG_LIST, resp);
          commit(SET_LOADING_STATE_BLOG, true);
        },
        () => {
          commit(SET_BLOG_LIST, {});
          commit(SET_LOADING_STATE_BLOG, false);
        }
      );
    }
  }
};
