import {
  USER_COUNTRY,
  USER_DATA,
  USER_PHOTO
} from "@/store/modules/user/_user.const";

export default {
  getters: {
    [USER_DATA](state) {
      return state.user.userData;
    },
    [USER_PHOTO](state) {
      return state.user.userData?.photo || null;
    },
    [USER_COUNTRY](state) {
      return state.user.userData?.country_id || state.user.userData?.country_id.id || 1;
    }
  }
};
