import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { getBigToken, getToken, removeToken } from "@/utils/token";
import { i18n } from "vue-lang-router";
// eslint-disable-next-line no-unused-vars
import storeFacade from "@/utils/facade/store/store.facade";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

http.interceptors.request.use(
  async config => {
    if (getToken()) {
      config.headers["Authorization"] = getToken();
    }
    config.headers["ch-language"] = i18n.locale;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  async response => {
    const res = response;
    if (res.status !== 200) {
      return Promise.reject({ state: false, ...res?.data });
    } else {
      if (!res.data?.error_code) return res;
      if (res.data?.error_code === "not_authorized") {
        removeToken();
        const originalRequest = response.config;
        if (getBigToken()) {
          await storeFacade.act("AUTH_USER_BY_TOKEN");
          originalRequest.headers["Authorization"] = getToken();
        } else {
          delete originalRequest.headers["Authorization"];
          await storeFacade.act("LOGOUT_USER");
        }
        originalRequest._retry = true;
        return http(originalRequest);
      }
      return Promise.reject({ state: false, ...res?.data });
    }
  },
  error => {
    return Promise.reject({
      error: error,
      color: "danger",
      message: error
    });
  }
);
export default http;
