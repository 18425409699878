import Vue from "vue";

Vue.component("AForm", () => import("./components/form/AForm"));
Vue.component("AInput", () => import("./components/form/AInput"));
Vue.component("ACheckbox", () => import("./components/form/ACheckBox"));
Vue.component("ATextarea", () => import("./components/form/ATextarea"));
Vue.component("ADropDown", () => import("./components/form/ADropDown"));
Vue.component("a-dropdown-list", () =>
  import("./components/dropdowns/a-dropdown-list")
);

Vue.component("icon-text-option", () =>
  import("./components/dropdowns/icon-text-option")
);
Vue.component("AImagePick", () => import("./components/form/AImagePick"));
Vue.component("AIcon", () => import("./components/AIcon/AIcon"));
Vue.component("AButton", () => import("./components/AButtons/AButton"));
Vue.component("ALink", () => import("./components/AButtons/ALink"));
Vue.component("AImage", () => import("./components/base/AImage"));
Vue.component("APagination", () =>
  import("./components/navigation/APagination")
);
Vue.component("circle-progress", () =>
  import("./assets/icons/circle-progress")
);
Vue.component("AModal", () =>
  import("./components/notifications/AModal/AModal")
);

// preloads templates
Vue.component("DetailPagePreloader", () =>
  import("./views/pages/sys/preloads/articles/detailPagePreloader")
);

// containers
Vue.component("filled-container", () =>
  import("@/containers/simple-panel/templates/filled-container")
);
Vue.component("transparent-container", () =>
  import("@/containers/simple-panel/templates/transparent-container")
);
