import http from "@/utils/http";
export const CreateArticle = async data =>
  await http({
    url: `/api/blog`,
    method: "post",
    data
  }).then(
    res => res.data,
    error => {
      throw error;
    }
  );
