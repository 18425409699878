<template>
  <div class="polygon__page">
    <AForm :end-valid.sync="form.endValid">
      <AInput
        v-model="form.testw"
        :validator="[
          {
            validator: /[^\s*$]/,
            invalidMessage: 'Поле не может быть пустым'
          },
          {
            validator: /^[0-9-]+$/,
            invalidMessage: 'Доступно только 0-9 и -'
          },
          {
            validator: /^[^\s*$]{5,10}$/,
            invalidMessage: 'от 5 до 10 символов, без пробелов',
            validMessage: 'Шикарно'
          }
        ]"
      >
        <template #append-content>
          <a-dropdown-list
            :a-dropdown-selected.sync="phone"
            position="center"
            class="phone-select"
          >
            <template #target-content>
              <div class="d-flex align-items-center">
                <AImage
                  v-if="cPhone.img"
                  :src="`${$base_media_url}/${cPhone.img}`"
                  :alt="cPhone.name || cPhone.img"
                />
                {{ cPhone.phone_code }}
              </div>
            </template>
            <icon-text-option
              :key="key"
              v-for="(country, key) in cCountry"
              :data="{ country }"
              :active="cPhone.id || cPhone"
            >
              {{ country.phone_code }}
            </icon-text-option>
          </a-dropdown-list>
        </template>
      </AInput>
      <AButton type="button" @click="test">asd</AButton>
      {{ cCountry }}
      <AInput
        v-model="form.test"
        :validator="[
          {
            validator: /[^\s*$]/,
            invalidMessage: 'Поле не может быть пустым'
          },
          {
            validator: /^[0-9-]+$/,
            invalidMessage: 'Доступно только 0-9 и -'
          }
        ]"
      />
      <ATextarea
        lazy-validator
        :validator="[
          {
            validator: /^[0-9-]+$/,
            invalidMessage: 'Доступно только 0-9 и -'
          },
          {
            validator: /^[^\s*$]{5,10}$/,
            invalidMessage: 'от 5 до 10 символов, без пробелов',
            validMessage: 'Шикарно'
          }
        ]"
        v-model="postData.short_description"
      />
      <AButton variant="outline">Submit</AButton>
    </AForm>
    <hr />
    <AButton @click="startUploadFile = true">Push</AButton>
    <AButton @click="saveArticle">Save</AButton>
    <AInput v-model="id" type="number" label="ID" />
    <AButton @click="loadDetail">Load</AButton>
    <AInput v-model="postData.lang_id" type="number" label="lang" />
    <AInput v-model="postData.title" label="title" />
    <AImagePick
      upload-url="https://zan.bar/media/temp"
      @uploaded="createArticle"
      :uploaded-files.sync="postData.main_image"
      :upload-start.sync="startUploadFile"
      only-path
      one-file
    />
    <ATextarea v-model="postData.short_description" />
    <ATextarea v-model="postData.body" />
    <ckeditor
      :editor="editor"
      v-model="postData.body"
      :config="editorConfig"
    ></ckeditor>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { CREATE_BLOG_ARTICLE } from "@/store/modules/articles/base/_base.const";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor.js";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment.js";
import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage.js";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import Autolink from "@ckeditor/ckeditor5-link/src/autolink.js";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter.js";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
import ExportToPDF from "@ckeditor/ckeditor5-export-pdf/src/exportpdf.js";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor.js";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize.js";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock.js";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
import Link from "@ckeditor/ckeditor5-link/src/link.js";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage.js";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle.js";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat.js";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough.js";
import Table from "@ckeditor/ckeditor5-table/src/table.js";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline.js";
// eslint-disable-next-line no-unused-vars
import { CATALOG_AC } from "@/store/modules/sys/catalog/_catalog.const";

export default {
  name: "v-polygon",
  computed: {
    cEndValid() {
      return this.form.endValid;
    },
    cCountry() {
      return this.$sf.get("CATALOG_AC", "GET_COUNTRIES");
    },
    cPhone(){
      return this.phone;
    }
  },
  watch: {
    "form.endValid"(val) {
      if (!val.size) {
        console.log("data:", val.size);
      }
    }
  },
  data: function() {
    return {
      phone: 1,
      form: {
        test: "",
        testw: "",
        endValid: {}
      },
      id: null,
      startUploadFile: false,
      postData: {
        title: "",
        short_description: "",
        body: "",
        lang_id: 1,
        usr_id: 1,
        country_id: 1,
        categories: [],
        main_image: [],
        is_test: false
      },
      oldDetailData: {},
      postDataEditing: {},
      postList: [],
      editorData: "",
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "fontBackgroundColor",
            "fontColor",
            "fontSize",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "horizontalLine",
            "alignment",
            "indent",
            "outdent",
            "|",
            "removeFormat",
            "imageInsert",
            "blockQuote",
            "insertTable",
            "undo",
            "redo",
            "exportPdf",
            "mediaEmbed"
          ]
        },
        language: "ru",
        image: {
          styles: ["full", "alignLeft", "alignRight"],
          toolbar: [
            "imageTextAlternative",
            "|",
            "imageStyle:alignLeft",
            "imageStyle:full",
            "imageStyle:alignRight"
          ]
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
        },
        licenseKey: "",
        plugins: [
          Alignment,
          AutoImage,
          Autoformat,
          Autolink,
          Base64UploadAdapter,
          BlockQuote,
          Bold,
          Essentials,
          ExportToPDF,
          FontBackgroundColor,
          FontColor,
          FontSize,
          Heading,
          HorizontalLine,
          Image,
          ImageCaption,
          ImageInsert,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListStyle,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          RemoveFormat,
          Strikethrough,
          Table,
          TableCellProperties,
          TableProperties,
          TableToolbar,
          TextTransformation,
          Underline
        ]
      }
    };
  },
  methods: {
    async loadDetail() {
      if (this.id)
        await this.$sf.act("LOAD_BLOG_DETAIL", { id: this.id }).then(i => {
          this.postData = i;
          this.oldDetailData = i.immutableCopy();
        });
    },
    async createArticle() {
      this.postData.lang_id = +this.postData.lang_id;
      await this.$store.dispatch(CREATE_BLOG_ARTICLE, this.postData);
    },
    async saveArticle() {
      // this.postData.lang_id = +this.postData.lang_id;
      await this.$sf.act("SAVE_BLOG_ARTICLE", {
        id: this.id,
        data: { body: this.postData.body }
      });
    },
    test() {
      console.log(this.$store.dispatch("test"));
    }
  }
};
</script>

<style scoped lang="scss">
.polygon__page {
  &::v-deep {
    .ck-content.ck-editor__editable {
      min-height: 21cm;
    }
  }
}
</style>
