import mutations from "@/store/modules/panel/_panel.mutations";
import states from "@/store/modules/panel/_panel.states";
import getters from "@/store/modules/panel/_panel.getters";
import actions from "@/store/modules/panel/_panel.actions";

export default {
  ...states,
  ...getters,
  ...mutations,
  ...actions
};
