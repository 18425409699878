Object.defineProperty(Object.prototype, "genKeys", {
  value: function(arg) {
    if (Array.isArray(arg)) {
      for (let keys of arg) if ((keys = this.genKeys(keys))) return keys;
      return false;
    } else
      return arg.split(".").reduce((acc, value) => acc[value] || false, this);
  },
  enumerable: false
});

Object.defineProperty(Object.prototype, "json2FormData", {
  value: function(fd = new FormData(), subKey = false) {
    Object.entries(this).reduce((acc, [key, value], i) => {
      let a = Array.isArray(value),
        al = a && value.length;
      if (al) value.json2FormData(fd, key);
      else acc.append(!subKey ? `${key}` : `${subKey}[${i}]`, value);
      return acc;
    }, fd);
    return fd;
  },
  enumerable: false
});

Object.defineProperty(Object.prototype, "convertToOption", {
  value: function(value, label) {
    return this.map(item => {
      let val = item.genKeys(value);
      return {
        value: val,
        label: label ? item.genKeys(label) : val
      };
    });
  },
  enumerable: false
});

Object.defineProperty(Object.prototype, "valuesToNull", {
  value: function() {
    return Object.keys(this).forEach(a => {
      return (this[a] = null);
    });
  },
  enumerable: false
});

Object.defineProperty(Object.prototype, "removeProperty", {
  value: function(wtd, values = false, removeEmptyValues = false) {
    let obj = this;
    for (let [keys, value] of Object.entries(obj)) {
      let test = (values ? value : keys)?.toString();
      if (
        wtd.length &&
        wtd.find(s =>
          removeEmptyValues
            ? !value
            : "" || !test || (s instanceof RegExp ? s.test(test) : test === s)
        )
      ) {
        // eslint-disable-next-line no-unused-vars
        let { [keys]: remove, ...rest } = obj;
        obj = rest;
      }
    }
    return obj;
  },
  enumerable: false
});

Object.defineProperty(Object.prototype, "immutableCopy", {
  value: function() {
    return JSON.parse(JSON.stringify(this));
  },
  enumerable: false
});

export const initEl = (el, hide = true, callback) => {
  el.style.position = "absolute";
  el.style.visibility = "hidden";
  el.style.display = el.style.display === "none" ? "block" : el.style.display;

  let computedHeight = getComputedStyle(el);
  if (callback) callback(computedHeight);
  el.style.position = null;
  el.style.visibility = null;
  if (hide) el.style.display = "none";
  return computedHeight;
};
