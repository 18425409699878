export default {
  cancel: "Отменить",
  save: "Сохранить",
  reset: "Сбросить",
  select: "Выбрать",
  case_category: "Категория дела",
  select_case_category: "Выберите категорию дела",
  description: "Описание",
  select_region: "Выбрать область",
  select_country: "Выбрать страну",
  attach_file: "Прикрепить файл",
  submit_application: "Оставить заявку",
  describe_the_task: "Опишите задачу",
  loading_title: "Загрузка заголовка",
  "not-required": "Не обязательно",
  see_all: "Смотреть все",
  useful_blog_posts: "Полезное в блоге",
  required: "Обязательно",
  navigation: "Навигация",
  contacts: "Навигация",
  social: "Мы в соцсетях",
  footer_app_text: "Для вашего удобства скачивайте мобильную версию приложения",
  create_article: "Создать статью",
  exit: "Выход",
  name: "ФИО",
  your_phone_number: "Ваш номер телефона",
  send: "Отправить",
};
