import Page404 from "@/views/pages/sys/Page404";

export default {
  path: "*",
  redirect: "/404",
  name: "Pages",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "404",
      name: "Page404",
      meta: { title: "not_found" },
      component: Page404
    }
  ]
};
