<template>
  <div class="article-author d-flex align-items-center">
    <AImage :src="cAvatar" :alt="label" />
    <h5 class="bold m-0 article-author__label">{{ label }}</h5>
  </div>
</template>

<script>
export default {
  name: "article-author",
  props: {
    src: String,
    label: [String, Number]
  },
  computed: {
    cAvatar() {
      return this.src;
    }
  }
};
</script>

<style scoped lang="scss">
.article-author {
  &::v-deep {
    .v-image {
      overflow: hidden;
      border-radius: 10em;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__label{
    color: var(--th-gray);
  }
}
</style>
