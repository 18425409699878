<template>
  <div class="list-items-preload row">
    <div :key="i" v-for="i in [1, 2, 3, 4, 5, 6]" class="list__item col-md-4">
      <div class="list__item-head"></div>
      <div class="list__item-info">
        <div class="list__item-info--place"></div>
        <div class="list__item-info--place"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "listItemsPreloader"
};
</script>

<style scoped lang="scss">
.list-items-preload {
  .animation-placeholder {
    position: relative;
    background: linear-gradient(#ebebeb, #b6b6b62b);
    overflow: hidden;
    &:after,
    &:before {
      content: "";
      background: linear-gradient(#ebebeb, #b6b6b62b);
      position: absolute;
      transform: skewX(-22deg);
      width: 30%;
      height: 100%;
      z-index: 10;
      animation: identifier linear 1s infinite;
    }
    &:after {
    }
    &:before {
      animation-delay: 500ms;
    }
  }
  .list__item {
    & > * {
      margin-bottom: 30px;
    }
    &-head {
      height: 280px;
      margin-bottom: 30px;
      @extend .animation-placeholder;
    }
    &-info {
      $places: (
        1: 20px,
        2: 40px,
      );
      @each $i, $height in $places {
        &--place:nth-child(#{ $i }) {
          height: $height;
          margin-bottom: 12px;
          @extend .animation-placeholder;
        }
      }
    }
  }
}

@keyframes identifier {
  from {
    transform: skewX(-22deg);
    left: -100%;
  }
  to {
    transform: skewX(-22deg);
    left: 100%;
  }
}
</style>
