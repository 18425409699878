<template>
  <router-view />
</template>
<script>
import { getToken } from "@/utils/token";

export default {
  name: "App",
  async mounted() {
    if (getToken()) await this.$sf.act("LOAD_USER_DATA");
    await this.$sf.act("LOAD_CATALOG");
  }
};
</script>
