import { LoadApplications } from "@/api/applicatoins.api";
import {
  LOAD_APPLICATIONS,
  SET_APPLICATIONS_LIST
} from "@/store/modules/applications/_applications.const";
import { SET_CATALOG } from "@/store/modules/sys/catalog/_catalog.const";

export default {
  actions: {
    async [LOAD_APPLICATIONS]({ commit }, payload) {
      await LoadApplications(payload).then(
        reps => {
          commit(SET_APPLICATIONS_LIST, reps);
          return reps;
        },
        error => {
          commit(SET_CATALOG, {});
          return error;
        }
      );
    }
  }
};
