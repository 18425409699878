import {
  LOAD_USER_DATA,
  SET_USER_DATA
} from "@/store/modules/user/_user.const";
import { LoadUserInfo } from "@/api/user.api";
import { SET_TYPE_PANEL } from "@/store/modules/panel/_panel.const";

export default {
  actions: {
    async [LOAD_USER_DATA]({ commit }) {
      await LoadUserInfo().then(resp => {
        let typePanel = resp.perms?.length
          ? "admin"
          : resp.type_id === 2
          ? "lawyer"
          : "user";

        commit(SET_USER_DATA, resp);
        commit(SET_TYPE_PANEL, typePanel || undefined);
      });
    }
  }
};
