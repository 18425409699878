import {
  SET_AUTH_MODAL,
  SET_BIG_TOKEN,
  SET_IS_LOGGED_IN,
  SET_TOKEN
} from "@/store/modules/auth/_auth.const";

export default {
  mutations: {
    [SET_IS_LOGGED_IN](state, value) {
      return (state.user.isLoggedIn = value);
    },
    [SET_AUTH_MODAL](state, value) {
      return (state.user.authModal = value);
    },
    [SET_TOKEN](state, value) {
      return (state.user.token = value);
    },
    [SET_BIG_TOKEN](state, value) {
      console.log(value)
      return (state.user.bigToken = value);
    }
  }
};
