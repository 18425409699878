<template>
  <div class="page404">
    404
  </div>
</template>

<script>
export default {
  name: "Page404"
};
</script>

<style scoped lang="scss">
.page404 {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  perspective: 1000px;
}
</style>
