import Vue from "vue";
import translations from "@/localization/index";
import LangRouter, { i18n } from "vue-lang-router";
import storeFacade from "@/utils/facade/store/store.facade";

//base pages
import Home from "@/views/pages/Home.vue";

import staticPagesRoute from "@/router/staticPagesRoute/staticPagesRoute";
import panelRoute from "@/router/panelRoute/panelRoute";
import sys from "@/router/sys.route";

const routes = [
  {
    path: "/",
    name: "Home Page",
    component: Home,
    meta: { dntShow: true }
  },
  staticPagesRoute,
  panelRoute,
  sys
];

Vue.use(LangRouter, {
  defaultLanguage: "en",
  translations
});

const router = new LangRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  let title = to.meta.title;
  document.title = i18n.te(title) ? i18n.t(title) : title || i18n.t("default_title_page_t");
  if (to.meta?.auth) {
    if (storeFacade.get("IS_LOGGED_IN")) {
      next();
    } else {
      router.push({ path: "/" }).catch(() => { });
    }
  } else {
    next();
  }
});

export default router;
