export default {
  items: [
    {
      question_ru: "Как оставить заявку в приложении Zan.Bar?",
      question_en: "How to submit a request in Zan.bar?",
      answer_ru:
        "<p>Чтобы оставить заявку, Вам необходимо открыть приложение Zan.Bar и нажать на вкладку «Оставить заявку».</p>",
      answer_en:
        '<p>To submit a request, you need to open Zan.Bar app and click on the "Make a request" button</p>'
    },
    {
      question_ru: "Я оставлял заявку, как со мной свяжутся юристы/адвокаты?",
      question_en: "I left a request, how will the lawyers contact me?",
      answer_ru:
        "<p>Вам необходимо открыть приложение Zan.Bar, нажать на вкладку «Я оставлял заявку». Далее ввести номер телефона, с которого Вы оставляли заявку. На экране отобразится Ваша личная страница.&nbsp;<br>Нажмите на раздел «Мои заявки», затем высветится Ваша заявка. Нажав на неё Вы увидите отклики от юристов/адвокатов, которые готовы Вам помочь. Они будут предлагать цены за свои услуги, а вам необходимо выбрать подходящего для себя юриста/адвоката. В приложении Вы можете написать или позвонить им напрямую.</p>",
      answer_en:
        '<p>You need to open the Zan.Bar app, click on the "Make a request" button. Next, enter the phone number from which you made the request. Your personal page will be displayed on the screen.&nbsp;</p><p>Click on the "My applications" button, then your application will be displayed. By clicking on it, you will see responses from lawyers who are ready to help you. They will offer prices for their services, and you need to choose the right lawyer/attorney for yourself. In the app, you can text or call them directly.</p>'
    },
    {
      question_ru:
        "Я уже решил свою проблему с юристом. Как мне завершить заявку?",
      question_en:
        "I've already solved my problem with the help of lawyer from Zan.bar. How can I complete the application?",
      answer_ru:
        "<p>Зайдите на свою личную страницу в приложении Zan.Bar. Нажмите на раздел «Мои заявки». В правом верхнем углу Вашей заявки будут три точки, нажав на них выйдут «Действия», выберите вкладку «Завершить». При завершении выйдет список юристов/адвокатов, которые откликались на Вашу заявку. Выберите юриста/адвоката, который помог Вам, оставьте отзыв и поставьте оценку.</p>",
      answer_en:
        '<p>Open your personal page in Zan.Bar app. Click on the "My applications" section. In the upper-right corner of your application there will be three dots, clicking on them you will see "Actions", select the "Complete". During completion, you will see a list of lawyers/attorneys who responded to your application. Choose a lawyer who helped you, leave a review and give a rating.</p>'
    },
    {
      question_ru:
        "Я уже решил свою проблему, но юрист не из платформы Zan.Bar. Как мне завершить заявку?",
      question_en:
        "I have already solved my problem, but the lawyer is not from Zan.Bar platform. How can I complete the application?",
      answer_ru:
        "<p>Зайдите на свою личную страницу в приложении Zan.Bar. Нажмите на раздел «Мои заявки». В правом верхнем углу Вашей заявки будут три точки, нажав на них выйдут «Действия», выберите вкладку «Завершить». При завершении выйдет список юристов/адвокатов, которые откликались на Вашу заявку. В самом низу будет окно «Исполнитель не найден», нажмите на нее и Ваша заявка станет неактивной.</p>",
      answer_en:
        '<p>Open your personal page in the Zan.Bar app. Click on the "My applications" section. In the upper right corner of your application there will be three dots, clicking on them you will see "Actions", select the "Complete" button. During completion, you will see a list of lawyers/attorneys who responded to your application. At the bottom you will see a window " Executor not found", click on it and your application will be inactive.</p>'
    },
    {
      question_ru: "Хочу отредактировать свою заявку. Как это сделать?",
      question_en: "I want to edit my application. How can I do this?",
      answer_ru:
        "<p>Зайдите на свою личную страницу в приложении Zan.Bar. Нажмите на раздел «Мои заявки». В правом верхнем углу Вашей заявки будут три точки, нажав на них выйдут «Действия», выберите вкладку «Редактировать». Вы сможете изменить описание Вашей заявки и категорию дела./адвокатов, которые откликались на Вашу заявку. В самом низу будет окно «Исполнитель не найден», нажмите на нее и Ваша заявка станет неактивной.</p>",
      answer_en:
        '<p>Open your personal page in Zan.Bar app. Click on the "My applications" section. In the upper right corner of your application there will be three dots, clicking on them you will open "Actions", select the "Edit". You can change the description of your application and the category of the case./attorneys who responded to your application. At the bottom you will see a window " Executor not found", click on it and your application will be inactive.</p>'
    },
    {
      question_ru:
        "Несете ли Вы ответственность за юристов/адвокатов, зарегистрировавшиеся в приложении Zan.Bar?",
      question_en:
        "Are you responsible for lawyers / attorneys who have registered in Zan.Bar app?",
      answer_ru:
        "<p>Все юристы перед регистрацией в приложении Zan.Bar проходят верификацию. Ответственность за качество выполненной работы несёт непосредственно сам юрист.\n" +
        "Мы предоставляем платформу по поиску юристов и адвокатов для наших клиентов./адвокатов, которые откликались на Вашу заявку. В самом низу будет окно «Исполнитель не найден», нажмите на нее и Ваша заявка станет неактивной.</p>",
      answer_en:
        '<p>All lawyers are subject to verification prior to registration in Zan.Bar app. Responsibility for the quality of the work performed is borne directly by the lawyer. We provide only a platform for finding lawyers for our clients./attorneys who responded to your application. At the bottom you will see a window " Executor not found", click on it and your application will be inactive.</p>'
    },
    {
      question_ru: "Хочу оставить заявку. Бесплатно ли это?",
      question_en: "I want to make a request. Is it free?",
      answer_ru:
        "<p>Да, приложение бесплатно. Вы только платите юристу/адвокату за оказанные услуги.",
      answer_en:
        "<p>Yes, the application is free of charge. You only pay a lawyer for the services rendered.</p>"
    },
    {
      question_ru: "У Вас дорогие цены за услуги",
      question_en: "You have expensive service prices",
      answer_ru:
        "<p>Вы не оплачиваете денежные средства приложению. Стоимость юридических услуг устанавливают сами юристы/адвокаты.",
      answer_en:
        "<p>You’re not paying for the app, it is free of charge. Legal fees are set by the lawyers themselves.</p>"
    },
    {
      question_ru:
        "Юрист просит сначала заплатить за работу. Как я буду уверена, что юрист выполнит свою работу?",
      question_en:
        "The lawyer asks me to pay for the job first. How do I know the lawyer will do his job?",
      answer_ru:
        "<p>Мы рекомендуем Вам заключить договор с юристом/адвокатом. У всех юристов/адвокатов есть типовой договор. Если Вы находитесь в разных городах, то попросите юриста выслать Вам договор с его подписью, затем подпишите Вы. Отсканируйте и вышлите юристу/адвокату. </p>",
      answer_en:
        "<p>We recommend you to sign a contract with a lawyer. All lawyers have a standard contract. If you are located in different cities, ask your lawyer to send you a contract with his signature, then you will sign it. Scan it and send it to your lawyer back.</p>"
    },
    {
      question_ru: "Почему на мою заявку никто не откликается?",
      question_en: "Why is no one responding to my request?",
      answer_ru:
        "<p>Советуем Вам для получения большего отклика от специалистов, заполнить подробное описание и выбрать правильную категорию дела. Во многих случаях специалисты не откликаются из-за недостаточных данных в заявке.</p>",
      answer_en:
        "<p>To get more feedback from specialists we recommend you to fill in the detailed description and\n" +
        "select the correct case category. In many cases, specialists do not respond because of insufficient data in the application.</p>"
    },
    {
      question_ru:
        "Можно ли не ожидая откликов от юристов, просмотреть все анкеты специалистов?",
      question_en:
        "Is it possible to view all the profiles of specialists without waiting for responses from lawyers?",
      answer_ru:
        "<p>Можно. После отправки заявки юристам, Вы сможете просмотреть анкеты всех зарегистрированных юристов в приложении, перейдя в раздел «Список».</p>",
      answer_en:
        '<p>Yes, it is. After sending the application to the lawyers, you will be able to view the profiles of all registered lawyers in the app by moving to the "List" section.</p>'
    }
  ]
};
