// mutations
export const SET_BLOG_LIST = "_list.mutations/SET_BLOG_LIST";
export const SET_LOADING_STATE_BLOG = "_list.mutations/SET_LOADING_BLOG";
// actions
export const LOAD_BLOG_LIST = "_list.action/LOAD_BLOG_LIST";
// getters
export const BLOG_LIST = "_list.getters/BLOG_LIST";
export const LOADING_STATE_BLOG = "_list.getters/LOADING_BLOG";

export default {
  SET_BLOG_LIST,
  LOAD_BLOG_LIST,
  BLOG_LIST,
  LOADING_STATE_BLOG,
  SET_LOADING_STATE_BLOG
};
