import http from "@/utils/http";

export const CreateRequest = async data =>
  await http({
    url: "/api/ads",
    method: "POST",
    data
  }).then(
    response => response?.data || {},
    error => {
      throw error;
    }
  );
