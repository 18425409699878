// actions
export const LOAD_CATALOG = "_catalog.actions/LOAD_CATALOG";
export const GET_CITY_BY_ID = "_catalog.actions/GET_CITY_BY_ID";
export const CATALOG_AC = "_catalog.actions/CATALOG_AC";

// mutations
export const SET_CATALOG = "_catalog.getters/SET_CATALOG";

// getter
export const CATALOG = "_catalog.getters/CATALOG";

// catalog getter
export const GET_CATALOG = {
  GET_CITY: "cities",
  GET_CATEGORY: "ad_categories",
  GET_COUNTRIES: "countries"
};

export default {
  LOAD_CATALOG,
  SET_CATALOG,
  CATALOG,
  GET_CATALOG,
  GET_CITY_BY_ID,
  CATALOG_AC
};
