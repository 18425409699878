import { CREATE_BLOG_ARTICLE } from "@/store/modules/articles/base/_base.const";
import { CreateArticle } from "@/api/articles/_actions.articles.api";

export default {
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async [CREATE_BLOG_ARTICLE]({}, payload) {
      await CreateArticle(payload).then(resp => resp);
    }
  }
};
