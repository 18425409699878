import {
  SEND_SMS,
  AUTH_USER_BY_SMS,
  AUTH_USER_BY_TOKEN,
  SET_IS_LOGGED_IN,
  LOGOUT_USER,
  SET_AUTH_MODAL
} from "@/store/modules/auth/_auth.const";
import {
  AuthUserBySms,
  AuthUserByToken,
  CheckUserPhone,
  LogoutUser
} from "@/api/user.api";
import {
  getBigToken,
  removeBigToken,
  removeToken,
  setBigToken,
  setToken
} from "@/utils/token";
import {
  LOAD_USER_DATA,
  SET_USER_DATA
} from "@/store/modules/user/_user.const";
import { SET_TYPE_PANEL } from "@/store/modules/panel/_panel.const";

export default {
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async [SEND_SMS]({}, payload) {
      let data = payload.immutableCopy();
      data.phone = data.phone.replace(/[() +\-\s]/g, "");
      return await CheckUserPhone(data).then(
        resp => resp,
        error => {
          throw error;
        }
      );
    },
    // eslint-disable-next-line no-empty-pattern
    async [AUTH_USER_BY_SMS]({ commit, dispatch }, payload) {
      let data = payload.immutableCopy();
      data.phone = data.phone.replace(/[() +\-\s]/g, "");
      data.sms_code = +data.sms_code;
      data = data.removeProperty(["member"]);
      return await AuthUserBySms(data).then(
        async resp => {
          setToken(resp.small_token);
          if (payload.member) setBigToken(resp.big_token);
          await dispatch(LOAD_USER_DATA);
          commit(SET_IS_LOGGED_IN, true);
          commit(SET_AUTH_MODAL, false);
        },
        error => {
          throw error;
        }
      );
    },
    async [LOGOUT_USER]({ commit }) {
      removeBigToken();
      removeToken();
      commit(SET_IS_LOGGED_IN, false);
      commit(SET_USER_DATA, null);
      commit(SET_TYPE_PANEL, null);
      return await LogoutUser();
    },
    // eslint-disable-next-line no-empty-pattern
    async [AUTH_USER_BY_TOKEN]({}, payload = getBigToken()) {
      return await AuthUserByToken({ big_token: payload }).then(
        resp => setToken(resp.small_token),
        error => {
          throw error;
        }
      );
    }
  }
};
