export default [
  {
    path: "/panel/user",
    icon: "user",
    label: "my_profile" // Профиль юриста
  },
  {
    path: "/panel/applications",
    icon: "check-circle",
    iconType: "stroke",
    label: "applications" //
  }
];
