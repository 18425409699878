<template>
  <div class="faq faq-sections">
    <h4 v-if="title" class="bold">{{ title }}</h4>
    <slot />
  </div>
</template>

<script>
export default {
  name: "faq-section",
  props: {
    title: String
  }
};
</script>

<style scoped lang="scss">
.faq-sections {
  margin-bottom: 3.125rem;
  h4 {
    color: var(--th-blue-dark);
    margin-bottom: 1.5625rem;
  }
}
</style>
