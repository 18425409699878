<template>
  <div>
    <div v-if="$i18n.locale === 'ru'" class="static-page">
      <p><strong>Что такое Zan.Bar?</strong></p>
      <p>
        <strong>Zan.Bar</strong> — это сервис, который помогает клиентам и
        юристам быстро найти друг друга.&nbsp;<br />Клиенты находят здесь
        профессиональных юристов по всем категориям для решения своих
        вопросов.<br />Всего в приложении Zan.Bar уже более 1 000 юристов и
        адвокатов, а также более 5000 довольных клиентов.<br />Частнопрактикующие
        юристы и компании находят с помощью Zan.Bar клиентов, а клиенты в
        кратчайшие сроки находят специалистов. Каждый день на нашем сайте
        появляется более 100 новых заказов.
      </p>
      <p><strong>Как оставить заявку в Zan.Bar?</strong></p>
      <ol>
        <li>Установите приложение Zan.Bar через AppStore/PlayMarket.</li>
        <li>Пройдите регистрацию.</li>
        <li>
          Выберите функцию «Оставить заявку». Оставляя заявку, обозначьте
          категорию и опишите Вашу задачу.
        </li>
        <li>
          Получайте предложения от юристов с ценами, рейтингами и отзывами.
        </li>
      </ol>
      <p><strong>Чем удобен Zan.Вar?</strong></p>
      <ol>
        <li>Работа только с проверенными юристами.</li>
        <li>
          Экономия времени (юристам постоянно приходят заявки, и они САМИ
          отправляют Вам предложения).
        </li>
        <li>Наличие цен, рейтингов и отзывов в профиле юриста.</li>
        <li>Большая база специалистов.</li>
        <li>Полная конфиденциальность.</li>
        <li>Простой и понятный интерфейс.</li>
        <li>Бесплатная система поиска.</li>
      </ol>
      <p>&nbsp;</p>
    </div>
    <div v-if="$i18n.locale === 'en'" class="static-page">
      <p><strong>What is Zan.bar?</strong></p>
      <p>
        <strong>Zan.bar - </strong>is a service that helps clients and lawyers
        to find each other.<br />Clients can find professional lawyers in all
        categories to solve their problems. Nowadays, there are more than
        1,000&nbsp;<br />lawyers and attorneys, and more than 5,000 satisfied
        clients.<br />With the help of <strong>Zan.bar</strong> private-practice
        lawyers and companies can find clients. More than 100 new orders appear
        in <strong>Zan.bar</strong> every day.
      </p>
      <p><strong>How to submit a request to Zan.bar?</strong></p>
      <ol>
        <li>Install the app in AppStore/PlayMarket.</li>
        <li>Complete the registration process.</li>
        <li>
          Select the option «MAKE A REQUEST». When submitting a request,
          indicate the category and describe your problem.
        </li>
        <li>
          Get suggestions from lawyers with the prices, ratings, and reviews.
        </li>
      </ol>
      <p><strong>Why Zan. bar is convenient service?</strong></p>
      <ol>
        <li>Working only with qualified lawyers.</li>
        <li>Time saving (lawyers THEMSELVES send you suggestions).</li>
        <li>
          Availability of prices, ratings and reviews in the lawyer's profile.
        </li>
        <li>Large base of specialists.</li>
        <li>Full confidentiality.</li>
        <li>Simple and intuitive interface.</li>
        <li>Free search system.</li>
      </ol>
    </div>
  </div>
</template>
<script>
export default {
  name: "Company",
};
</script>
