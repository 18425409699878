<template>
  <transparent-container>
    <applications-list>
      <applications-items
        v-for="(item, kay) in cApplicationsList.results"
        :key="kay"
        :data="{ ...item, cCategory }"
      />
    </applications-list>
    <APagination
      :current-page.sync="params.page"
      :total="cApplicationsList.total_count"
      :per-page="perPage"
      @page-changed="loadApplications"
    />
  </transparent-container>
</template>
<script>
import applicationsList from "@/components/panel/applications/applications-list";
import applicationsItems from "@/components/panel/applications/applications-item";

export default {
  name: "Applications",
  data() {
    return {
      params: {
        page: 1,
        page_size: 10
      },
      perPage: 10
    };
  },
  components: {
    applicationsList,
    applicationsItems
  },
  computed: {
    cApplicationsList() {
      return this.$sf.get("APPLICATIONS_LIST");
    },
    cCategory() {
      return this.$sf.get("CATALOG")?.ad_categories || [];
    }
  },
  async mounted() {
    // await this.$sf.act("LOAD_CATALOG");
    await this.loadApplications();
  },
  methods: {
    async loadApplications() {
      await this.$sf.act("LOAD_APPLICATIONS", this.params);
    }
  }
};
</script>
