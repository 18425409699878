import mutations from "@/store/modules/sys/catalog/_catalog.mutations";
import states from "@/store/modules/sys/catalog/_catalog.states";
import getters from "@/store/modules/sys/catalog/_catalog.getters";
import actions from "@/store/modules/sys/catalog/_catalog.actions";

export default {
  ...states,
  ...getters,
  ...mutations,
  ...actions
};
