<template>
  <div class="lawyer-aside">
    <aside-user />
    <hr />
    <navigation-bar :items="cAsideNav" />
  </div>
</template>

<script>
import NavigationBar from "@/components/navigation/NavigationBar";
import props from "@/containers/simple-panel/templates/aside/aside-props";
import asideUser from "@/components/panel/aside-user/aside-user";

export default {
  name: "lawyer",
  props,
  components: {
    asideUser,
    NavigationBar
  },
  computed: {
    cAsideNav() {
      return this.asideNavigate;
    }
  }
};
</script>

<style scoped lang="scss">
.lawyer-aside {
  height: calc(100vh - 128px);
  overflow-y: auto;
  padding: 0 30px;
  hr {
    margin-left: -30px;
    margin-right: -30px;
  }
}
</style>
