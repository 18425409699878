<template>
  <nav class="aside-nav">
    <ALink
      :to="nav.path"
      class="aside-nav__item"
      :class="`type-${nav.iconType || 'fill'}`"
      :key="key"
      v-for="(nav, key) in items"
    >
      <AIcon
        width="32"
        height="32"
        :name="nav.icon"
      />
      {{ $te(nav.label) ? $t(nav.label) : nav.label }}
    </ALink>
  </nav>
</template>

<script>
export default {
  name: "NavigationBar",
  props: {
    items: Array
  }
};
</script>

<style scoped lang="scss">
.aside-nav {
  &__item {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: var(--th-gray);
    font-weight: 600;
    transition: all 200ms;
    text-decoration: none;
    padding: 10px;
    margin-bottom: 5px;

    svg {
      margin-right: 10px;
      display: block;

      &::v-deep {
        * {
          transition: all 200ms;
          fill: var(--th-gary-light);
          stroke: none;
        }
      }
    }

    &.router-link-exact-active,
    &:hover {
      color: var(--th-blue-dark);

      svg {
        &::v-deep {
          * {
            fill: var(--th-blue-dark);
            stroke: none;
          }
        }
      }
    }

    &.type {
      &-stroke {
        svg {
          &::v-deep {
            * {
              stroke: var(--th-gary-light);
              fill: none;
            }
          }
        }

        &.router-link-exact-active,
        &:hover {
          svg {
            &::v-deep {
              * {
                stroke: var(--th-blue-dark);
                fill: none;
              }
            }
          }
        }
      }


      &-fill {
        svg {
          &::v-deep {
            * {
              fill: var(--th-gary-light);
              stroke: none;
            }
          }
        }

        &.router-link-exact-active,
        &:hover {
          svg {
            &::v-deep {
              * {
                fill: var(--th-blue-dark);
                stroke: none;
              }
            }
          }
        }
      }
    }

  }
}
</style>
