import {
  AUTH_MODAL,
  BIG_TOKEN,
  IS_LOGGED_IN,
  TOKEN
} from "@/store/modules/auth/_auth.const";

import { getToken } from "@/utils/token";

export default {
  getters: {
    [IS_LOGGED_IN]() {
      return !!getToken();
    },
    [AUTH_MODAL](state) {
      return state.user.authModal;
    },
    [TOKEN](state) {
      return state.user.token;
    },
    [BIG_TOKEN](state) {
      return state.user.bigToken;
    }
  }
};
