<template>
  <div class="d-flex align-items-stretch flex-wrap applications-list">
    <slot />
  </div>
</template>

<script>
export default {
  name: "applications-list"
};
</script>

<style scoped lang="scss">
.applications-list {
  margin: -15px;
}
</style>
