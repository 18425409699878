import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
let ls = new SecureLS({ isCompression: false });
Vue.use(Vuex);

const modulesFiles = require.context("./modules", true, /^[^_]+\.js/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "client",
      paths: [
        "user/user.user.userData",
        "panel/panel.panel.typePanel",
        "auth/auth.user.token",
        "auth/auth.user.bigToken",
        "sys/catalog.catalog"
      ],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  modules
});
