<template>
  <articles-detail
    :loading="loading"
    :title="cContent.title"
    :date="$moment(cContent.created_at).format('DD.MM.YYYY')"
    :content="cContent.body"
    :author="cAuth[$i18n.locale][this.$route.params.id]"
  />
</template>

<script>
import articlesDetail from "@/components/articles/articles-detail";

export default {
  name: "articles-detail-page",
  components: { articlesDetail },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    cContent() {
      return this.$sf.get("BLOG_DETAIL") || {};
    },
    cAuth() {
      return {
        en: {
          41: {
            name: "Kenbay Aubakirov, Zan.Bar lawyer",
            key: "kenbay_aubakirov"
          },
          40: {
            name: "Zhanara Yerzhanova, Zan.Bar lawyer",
            key: "zhanara_yerzhanova"
          },
          39: {
            name: "Zhanara Yerzhanova, Zan.Bar lawyer",
            key: "zhanara_yerzhanova"
          },
          38: {
            name: "Askhat Kuanyshbayev, Zan.Bar lawyer",
            key: "askhat_kuanyshbayev"
          },
          37: {
            name: "Irina Kalinger, Zan.Bar lawyer",
            key: "irina_kalinger"
          },
          36: {
            name: "Asem Tusupova, Zan.Bar lawyer",
            key: "asem_tusupova"
          },
          35: {
            name: "Zhanara Yerzhanova, Zan.Bar lawyer",
            key: "zhanara_yerzhanova"
          }
        },
        ru: {
          34: {
            name: "Аубакиров Кенбай, юрист Zan.Bar",
            key: "kenbay_aubakirov"
          },
          33: {
            name: "Тусупова Асем, юрист Zan.Bar",
            key: "asem_tusupova"
          },
          32: {
            name: "Ержанова Жанара Жумагалиевна, юрист Zan.Bar",
            key: "zhanara_yerzhanova"
          },
          31: {
            name: "Ержанова Жанара Жумагалиевна, юрист Zan.Bar",
            key: "zhanara_yerzhanova"
          },
          30: {
            name: "Куанышбаев Асхат, юрист Zan.Bar",
            key: "askhat_kuanyshbayev"
          },
          29: {
            name: "Ержанова Жанара Жумагалиевна, юрист Zan.Bar",
            key: "zhanara_yerzhanova"
          },
          28: {
            name: "Калингер Ирина Владимировна, юрист Zan.Bar",
            key: "irina_kalinger"
          }
        }
      };
    }
  },
  async mounted() {
    // Установить поиск
    this.$parent.$parent.breadcrumbs = true;
    this.$parent.$parent.pageTitle = false;
    // loading place
    await this.$sf.act("LOAD_BLOG_DETAIL", { id: this.$route.params.id });
    this.loading = false;
  },
  // После смены страницы сбросить состояние
  beforeDestroy() {
    this.$parent.$parent.pageTitle = true;
    this.$parent.$parent.breadcrumbs = false;
    this.$store.dispatch("ClearDetailArticle");
  }
};
</script>

<style scoped></style>
