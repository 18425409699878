import http from "@/utils/http";

export const CheckUserPhone = data => {
  return http({url: "/api/profile/send_validating_code", method: "post", data}).then(
    response => response,
    error => {
      throw error;
    }
  )
};

export const AuthUserBySms = data =>
  http({ url: "/api/profile/auth", method: "post", data }).then(
    response => response.data,
    error => {
      throw error;
    }
  );

export const AuthUserByToken = data =>
  http({ url: "/api/profile/auth_by_token", method: "post", data }).then(
    response => response.data,
    error => {
      throw error;
    }
  );

export const LogoutUser = data =>
  http({ url: "/api/profile/logout", method: "post", data }).then(
    response => response.data,
    error => {
      throw error;
    }
  );

export const LoadUserInfo = params =>
  http({ url: "/api/profile", params }).then(
    response => response.data,
    error => {
      throw error;
    }
  );

export const RegUser = data =>
  http({ url: "/api/profile/reg", method: "post", data }).then(
    response => response.data,
    error => {
      throw error;
    }
  );
