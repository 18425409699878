import {
  SET_ACTIVE_ARTICLE_ID,
  SET_ACTIVE_ARTICLE_CONTENT
} from "@/store/modules/articles/detail/_detail.const";

export default {
  mutations: {
    [SET_ACTIVE_ARTICLE_ID](state, payload) {
      state.detail.activeID = payload;
    },
    [SET_ACTIVE_ARTICLE_CONTENT](state, payload) {
      state.detail.activeContent = payload;
    }
  }
};
