export default {
  cancel: "Cancel",
  save: "Save",
  reset: "Reset",
  select: "Select",
  case_category: "Case category",
  select_case_category: "Select a case category",
  description: "Description",
  select_region: "Region",
  select_country: "Region",
  attach_file: "Attach file",
  submit_application: "Leave a request",
  describe_the_task:
    "Describe the problem you have encountered with in detail…",
  loading_title: "Loading title",
  "not-required": "Is not required",
  see_all: "All",
  useful_blog_posts: "Useful blogs",
  required: "Required",
  navigation: "Navigation",
  contacts: "Contacts",
  social: "We are in social networks",
  footer_app_text: "For your convenience, download the mobile version of the app",
  create_article: "Create article",
  exit: "Exit",
  name: "Name",
  your_phone_number: "Your phone number",
  send: "Send",
};
