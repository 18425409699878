<template>
  <div class="aside-user">
    <AImage :src="$base_media_url + '/' + $sf.get('USER_PHOTO')" />
    <div class="aside-user__info text-center">
      <p class="paraq-1" v-if="cUserData.name">{{ cUserData.name || "" }}</p>
      <h5 class="bold">Баланс: 15 000тг</h5>
    </div>
    <div class="aside-user__actions">
      <AButton class="text-uppercase bold" variant="outline">
        пополнить баланс
      </AButton>
      <AButton class="text-uppercase bold" variant="outline">
        создать услугу
      </AButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "aside-user",
  computed: {
    cUserData() {
      return this.$sf.get("USER_DATA") || {};
    }
  }
};
</script>

<style scoped lang="scss">
.aside-user {
  padding: 20px 0;
  &::v-deep {
    .v-image {
      width: 120px;
      height: 120px;
      border-radius: 10em;
      overflow: hidden;
      margin: 0 auto 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10em;
      }

      .img {
        &--preloader,
        &--none {
          @extend .v-image;
          border: 1px solid #21405a;
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  &__info {
    margin-bottom: 20px;
    .paraq-1 {
      margin-bottom: 5px;
    }
  }
  &__actions {
    button {
      width: 100%;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
