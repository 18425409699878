<template>
  <div class="static-page">
    <faq-section
      v-for="(section, k) in faqData"
      :title="section['sectionName_' + $i18n.locale]"
      :key="k"
    >
      <faq-item
        @click="opened = opened === k + '_' + kk ? null : k + '_' + kk"
        :open="opened === k + '_' + kk"
        :id="k + '_' + kk"
        v-for="(item, kk) in section.items"
        :title="item['question_' + $i18n.locale]"
        :key="k + '_' + kk"
        :html="item['answer_' + $i18n.locale]"
      />
    </faq-section>
  </div>
</template>

<script>
import section_1 from "@/views/pages/faq-data/section_1";

import faqSection from "@/components/faq/faq-section";
import faqItem from "@/components/faq/faq-item";
export default {
  name: "Faq",
  components: {
    faqSection,
    faqItem
  },
  data() {
    return {
      faqData: [section_1],
      opened: null
    };
  },

};
</script>
