// actions
export const SEND_SMS = "_auth.action/SEND_SMS";
export const AUTH_USER_BY_SMS = "_auth.action/AUTH_USER_BY_SMS";
export const AUTH_USER_BY_TOKEN = "_auth.action/AUTH_USER_BY_TOKEN";
export const LOGOUT_USER = "_auth.action/LOGOUT_USER";

// mutations
export const SET_IS_LOGGED_IN = "_auth.mutations/SET_IS_LOGGED_IN";
export const SET_AUTH_MODAL = "_auth.mutations/SET_AUTH_MODAL";
export const SET_TOKEN = "_auth.mutations/SET_TOKEN";
export const SET_BIG_TOKEN = "_auth.mutations/SET_BIG_TOKEN";

// getter
export const IS_LOGGED_IN = "_auth.getters/IS_LOGGED_IN";
export const AUTH_MODAL = "_auth.getters/AUTH_MODAL";
export const TOKEN = "_auth.getters/TOKEN";
export const BIG_TOKEN = "_auth.getters/BIG_TOKEN";

export default {
  SEND_SMS,
  AUTH_USER_BY_SMS,
  AUTH_USER_BY_TOKEN,
  IS_LOGGED_IN,
  SET_IS_LOGGED_IN,
  LOGOUT_USER,
  SET_AUTH_MODAL,
  AUTH_MODAL,
  SET_TOKEN,
  SET_BIG_TOKEN,
  TOKEN,
  BIG_TOKEN
};
