<template>
  <div :class="cClassBlock">
    <ALink :to="to || '#'" class="article__item__link">
      <div class="article__item__preview">
        <AIcon
          class="article__logotype"
          icon-stroke="none"
          width="48"
          height="48"
          name="zb-logotype-without-text"
        />
        <slot name="preview">
          <AImage :src="preview" :alt="title" />
        </slot>
      </div>
      <div class="article__item__info">
        <div class="info">
          <div v-if="!!cCategory" class="info-category__wrap">
            <div
              :key="key"
              v-for="(cat, key) in cCategory"
              class="info-category"
            >
              <h5 class="bold">{{ cat.name }}</h5>
            </div>
          </div>
          <div class="info-title">
            <h4 class="bold">{{ title }}</h4>
            <articles-author
              :src="`/demo-assets/images/${cAuthor.key}.jpg`"
              :label="cAuthor.name"
              class="mb-2"
            />
          </div>
          <h5 class="info-date bold">{{ date }}</h5>
          <div class="info__little-description">
            <slot />
          </div>
        </div>
        <div class="text-uppercase read-more d-flex align-items-center">
          читать далее
          <AIcon name="arrow-right-sc" />
        </div>
      </div>
    </ALink>
  </div>
</template>

<script>
import articlesAuthor from "@/components/articles/article-author";
export default {
  name: "articles-list-item",
  props: {
    title: String,
    date: String,
    preview: String,
    category: Array,
    htmlDescription: undefined,
    to: [Object],
    sm: String,
    author: [Array, Object, String],
    md: {
      type: String,
      default: "md"
    },
    lg: String,
    xl: String
  },
  components: {
    articlesAuthor
  },
  computed: {
    cClassBlock() {
      return ["article article__item", "col-md-4"];
    },
    cCategory() {
      return this.category.length ? this.category : false;
    },
    cAuthor() {
      return this.author || {};
    }
  }
};
</script>

<style scoped lang="scss">
.article {
  margin-bottom: 42px;

  &__item {
    &__preview {
      margin-bottom: 20px;
      overflow: hidden;
      border-radius: 5px;
      position: relative;

      .article__logotype {
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 2;
      }

      &::v-deep .v-image {
        img,
        svg {
          min-height: 280px;
          max-height: 280px;
          width: 100%;
          object-fit: cover;
          transition: 300ms all;
        }

        .img--preloader,
        .img--none {
          svg {
            width: 100px;
            height: auto;
          }
        }
      }
    }

    &__info {
      //display: none;
      .read-more {
        svg {
          margin-left: 19px;
        }
      }

      .info {
        margin-bottom: 19px;

        &-title {
          h4 {
            margin-bottom: 22px;
            color: var(--th-blue-dark);
          }
        }

        &-category {
          padding: 5px 15px;
          border: 1px solid var(--th-blue-light);
          text-align: center;
          border-radius: 5px;
          display: inline-block;
          margin-bottom: 20px;
          margin-right: 5px;
          margin-left: 5px;

          h5 {
            display: inline-block;
            margin: 0;
            line-height: 24px;
            color: var(--th-blue-light);
          }

          &:not(&:only-of-type) {
            flex-grow: 1;
          }

          &__wrap {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-right: -5px;
            margin-left: -5px;
          }
        }

        &-date {
          margin-bottom: 15px;
          color: var(--th-gray);
        }

        &__little-description {
          color: var(--th-gray-dark);
          font-weight: 500;
          font-size: 1.125rem;
          position: relative;
          line-height: 1.625rem;
          &:after {
            content: "";
            position: absolute;
            width: 128px;
            height: 28px;
            bottom: 0;
            right: 0;
            background: linear-gradient(
              270deg,
              #fcfcfc 0%,
              rgba(252, 252, 252, 0.8) 30.28%,
              rgba(252, 252, 252, 0) 111.79%
            );
          }
        }
      }
    }
    &__link {
      display: block;
      text-decoration: none;
      &:hover {
        .article__item__preview{
          &::v-deep {
            .v-image {
              img,
              svg {
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }
  }
}
</style>
