<template>
  <div class="auth-modal">
    <AModal :show.sync="cAuthModal" size="sm">
      <div>
        <h4 class="bold text-center mb-40 color-blue-dark">
          Вы юрист?<br />
          Войдите в профиль
        </h4>
        <AForm @submit-form="auth" :end-valid.sync="errors">
          <AInput
            required
            label="Ваш номер телефона"
            v-mask="'+7 (###) ###-##-##'"
            placeholder="+7 (777) 777-77-77"
            v-model="userData.phone"
            :validator="[
              {
                validator: /[^\s*$]/,
                invalidMessage: 'Обязательно к заполнению',
                invalid: () => (this.smsForm = false)
              },
              {
                validator: /^[0-9+\-()\s*]{18}$/,
                invalidMessage: 'Не верно введен номер',
                invalid: () => (this.smsForm = false)
              }
            ]"
          />
          <AInput
            required
            autocomplete="off"
            v-if="smsForm"
            label="Введите код"
            v-mask="'####'"
            placeholder="####"
            v-model="userData.sms_code"
            :validator="[
              {
                validator: /[^\s*$]/,
                invalidMessage: 'Обязательно к заполнению'
              },
              {
                validator: /^[0-9]{4}$/,
                invalidMessage: 'Минимум 4 символа'
              }
            ]"
          />
          <AButton
            @click.prevent="validPhone"
            v-if="smsForm"
            variant="link"
            class="p-0 mb-3"
          >
            Отправить еще раз
          </AButton>
          <ACheckbox :checked.sync="userData.member" label="Запомнить меня" />
          <AButton class="w-100 mt-40 text-uppercase" :loading="sendSmsButton">
            {{ smsForm ? "Авторизироваться" : "Запросить код" }}
          </AButton>
        </AForm>
      </div>
    </AModal>
  </div>
</template>

<script>
export default {
  name: "authComponent",
  data() {
    return {
      errors: 0,
      sendSmsButton: false,
      smsForm: false,
      alert: null,
      test: '',
      userData: {
        phone: null,
        member: false,
        sms_code: null
      }
    };
  },
  computed: {
    cAuthModal: {
      get() {
        return this.$sf.get("AUTH_MODAL");
      },
      set(val) {
        this.$sf.set("SET_AUTH_MODAL", val);
      }
    },
    cError() {
      return this.errors;
    },
    cAlert() {
      return this.alert;
    }
  },
  watch: {
    cAuthModal(val) {
      if (val) {
        this.smsForm = false;
        this.userData.valuesToNull();
      };
    }
  },
  methods: {
    async auth({ valid }) {
      if (!valid.size) {
        if (!this.smsForm) await this.validPhone();
        else await this.validSms();
      }
    },

    async validPhone() {
      this.smsForm = false;
      this.userData.sms_code = null;
      this.sendSmsButton = true;
      await this.$sf.act("SEND_SMS", { phone: this.userData.phone }).then(
        () => (this.smsForm = true),
        error => (this.alert = error)
      );
      this.sendSmsButton = false;
    },
    async validSms() {
      this.sendSmsButton = true;
      await this.$sf.act("AUTH_USER_BY_SMS", this.userData).then(
        e => {
          console.log(e);
        },
        error => (this.alert = error)
      );
      this.sendSmsButton = false;
    }
  }
};
</script>
