import VueGtm from "vue-gtm";
import { ToastPlugin } from "bootstrap-vue";
Vue.use(ToastPlugin);
require("./components");
// require("@/utils/inject");
// eslint-disable-next-line no-unused-vars
import Vue from "vue";
import VueMask from "v-mask";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { i18n } from "vue-lang-router";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueMask);

Vue.config.productionTip = false;

import("./assets/scss/_main.scss");

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VueBreadcrumbs from "vue-2-breadcrumbs";

Vue.use(VueBreadcrumbs);

Vue.use(PerfectScrollbar);

import moment from "moment";
Vue.prototype.$moment = moment;
Vue.prototype.$base_url = process.env.VUE_APP_API_URL;
Vue.prototype.$base_media_url = process.env.VUE_APP_MEDIA_URL;

import CKEditor from "@ckeditor/ckeditor5-vue2";
Vue.use(CKEditor);

import storeFacade from "@/utils/facade/store/store.facade";
import {initEl} from "@/utils/inject";

Vue.prototype.$sf = storeFacade;
Vue.prototype.$initEl = initEl;


Vue.use(VueGtm, {
  id: "GTM-KWLHW99", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  // queryParams: {
  //   // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
  //   gtm_preview: "env-4",
  //   gtm_cookies_win: "x",
  // },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});


new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
