import {SET_USER_COUNTRY, SET_USER_DATA} from "@/store/modules/user/_user.const";

export default {
  mutations: {
    [SET_USER_DATA](state, values) {
      return (state.user.userData = values);
    },
    [SET_USER_COUNTRY](state, values) {
      return (state.user.userData.country_id = values);
    }
  }
};
