<template>
  <aside>
    <div class="logo-bar">
      <AIcon icon-stroke="none" width="242" height="77" name="logoZanBar" />
    </div>
    <component :aside-navigate="cNav" v-if="cTypePanel" :is="cTypePanel" />
  </aside>
</template>
<script>
// aside templates
import admin from "@/containers/simple-panel/templates/aside/admin";
import lawyer from "@/containers/simple-panel/templates/aside/lawyer";
import nav from "@/containers/nav";

export default {
  name: "aside-panel",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    admin,
    lawyer
  },
  computed: {
    cTypePanel() {
      return this.$sf.get("TYPE_PANEL");
    },
    cNav() {
      return nav[this.cTypePanel] || [];
    }
  }
};
</script>

<style scoped lang="scss">
aside {
  box-shadow: -6px 13px 57px rgba(94, 98, 105, 0.15);
  background: var(--th-white);
  z-index: 1;
  position: relative;
  min-width: 315px;
  > .logo-bar {
    padding: 30px 30px 0;
    margin-bottom: 21px;
  }
}
</style>
