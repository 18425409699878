// actions
export const LOAD_USER_DATA = "_user.action/LOAD_USER_DATA";

// mutations
export const SET_USER_DATA = "_user.mutations/SET_USER_DATA";
export const SET_USER_COUNTRY = "_user.mutations/SET_USER_COUNTRY";

// getter
export const USER_DATA = "_user.getters/USER_DATA";
export const USER_PHOTO = "_user.getters/USER_PHOTO";
export const USER_COUNTRY = "_user.getters/USER_COUNTRY";

export default {
  USER_DATA,
  SET_USER_DATA,
  LOAD_USER_DATA,
  USER_PHOTO,
  USER_COUNTRY,
  SET_USER_COUNTRY
};
