<template>
  <header>
    <div class="container">
      <div class="top-line d-flex align-items-center justify-content-between">
        <div class="logotype__wrapper">
          <localized-link to="/">
            <AIcon
              icon-stroke="none"
              icon-fill="var(--th-white)"
              width="243"
              height="78"
              name="logoZanBar"
            />
          </localized-link>
        </div>
        <nav class="header__nav ml-auto">
          <localized-link to="/">{{ $t("main") }}</localized-link>
          <localized-link to="/company">
            {{ $t("about_company") }}
          </localized-link>
          <localized-link to="/faq">{{ $t("faq") }}</localized-link>
          <localized-link to="/blog">{{ $t("blog") }}</localized-link>
        </nav>
        <language-switcher class="ml-5" v-slot="{ links }">
          <router-link
            v-for="link in links"
            :key="link.langIndex"
            :to="link.url"
          >
            <h5>{{ link.langName }}</h5>
          </router-link>
        </language-switcher>
<!--        <AButton-->
<!--          v-show="!cIsLoggedIn"-->
<!--          class="text-uppercase"-->
<!--          @click="$sf.set('SET_AUTH_MODAL', true)"-->
<!--        >-->
<!--          <AIcon class="mr-2" name="man" icon-stroke="none" />-->
<!--          Авторизация-->
<!--        </AButton>-->
<!--        <AButton-->
<!--          v-show="cIsLoggedIn"-->
<!--          class="text-uppercase"-->
<!--          @click="$sf.act('LOGOUT_USER')"-->
<!--        >-->
<!--          <AIcon class="mr-2" name="logout" icon-fill="white" />-->
<!--          Выход-->
<!--        </AButton>-->
      </div>
    </div>
    <auth-component ref="authComponent" />
  </header>
</template>
<script>
import authComponent from "@/containers/authComponent";
const { getToken } = require("@/utils/token");
export default {
  getToken,
  name: "commonHeader",
  components: {
    authComponent
  },
  computed: {
    cIsLoggedIn() {
      return this.$sf.get("IS_LOGGED_IN");
    }
  }
};
</script>

<style scoped lang="scss">
header {
  margin-bottom: 35px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.top-line {
  padding: 25px 0;
}

nav.header__nav {
  a {
    text-transform: uppercase;
    margin: 0 calc(35px / 2);
  }
}
</style>
