import http from "@/utils/http";
export const LoadActiveArticle = params =>
  http({
    url: `/api/blog/${params.id}`
  }).then(
    res => res.data,
    error => {
      throw error;
    }
  );

export const SaveActiveArticle = params =>
  http({
    url: `/api/blog/${params.id}`,
    data: params.data,
    method: "put"
  }).then(
    res => res.data,
    error => {
      throw error;
    }
  );
