<template>
  <div class="static-page__layout">
    <common-header />
    <div class="static-page__content container">
      <div v-if="pageTitle" class="d-flex align-items-center flex-wrap">
        <h2 class="bold">
          {{
            $te($route.meta.title) ? $t($route.meta.title) : $route.meta.title
          }}
        </h2>
        <AInput
          v-if="searchLayoutShow"
          class="search-layout ml-auto"
          v-model="searchLayout"
          placeholder="Поиск..."
          name="search-in-page"
        />
      </div>
      <breadcrumbs v-if="breadcrumbs" />
      <router-view
        ref="content"
        :search-layout.sync="searchLayout"
        :key="this.$route.path"
      ></router-view>
    </div>
    <common-footer />
  </div>
</template>

<script>
/*
import StaticPageHeader from "./StaticPageHeader";
import StaticPageFooter from "./StaticPageFooter";
*/
import commonHeader from "@/containers/commonHeader";
import commonFooter from "@/containers/commonFooter";

import ContainersData from "../containers-data";
import ContainersProps from "../containers-props";
import ContainersComputed from "../containers-computed";
import breadcrumbs from "@/components/breadcrumb/breadcrumb";

export default {
  name: "StaticPageLayout",
  mixins: [ContainersData, ContainersProps, ContainersComputed],
  components: {
    breadcrumbs,
    commonHeader,
    commonFooter
  }
};
</script>

<style scoped lang="scss">
body {
  display: flex;
}
.static-page {
  margin-bottom: 120px;
  position: relative;
}
.static-page__layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  h2,
  .search-layout {
    margin-bottom: 30px;
  }
  h2 {
    color: var(--th-blue-dark);
  }
  .search-layout {
    width: 100%;
    max-width: 356px;
  }
}
</style>
