export default {
  data() {
    return {
      pageTitle: true,
      searchLayoutShow: false,
      breadcrumbs: false,
      searchLayout: ""
    };
  }
};
