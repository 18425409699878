<template>
  <div class="static-page">
    <listItemsPreloader v-if="!cLoadingState" />
    <transition name="fade">
      <articles-list v-if="cLoadingState">
        <articles-list-item
          :author="author[$i18n.locale][post.id]"
          :preview="$base_media_url + '/' + post.main_image"
          :date="$moment(post.created_at).format('DD.MM.YYYY')"
          :title="post.title"
          :category="categories[$i18n.locale][post.id] || post.categories"
          :key="key"
          v-for="(post, key) in cBlogList.results"
          :to="{
            path: `/blog/catalog/${post.id}`,
            params: { title: `post ${post.id}` }
          }"
        >
          {{ post.short_description.slice(0, 100) }}
        </articles-list-item>
      </articles-list>
    </transition>
  </div>
</template>
<script>
import articlesList from "@/components/articles/articles-list";
import articlesListItem from "@/components/articles/articles-list-item";
import listItemsPreloader from "@/views/pages/sys/preloads/articles/listItemsPreloader";

export default {
  name: "Articles",
  props: {
    searchLayout: String
  },
  data() {
    return {
      params: {
        is_test: false,
        page_size: 10
      },
      author: {
        en: {
          41: {
            name: "Kenbay Aubakirov, Zan.Bar lawyer",
            key: "kenbay_aubakirov"
          },
          40: {
            name: "Zhanara Yerzhanova, Zan.Bar lawyer",
            key: "zhanara_yerzhanova"
          },
          39: {
            name: "Zhanara Yerzhanova, Zan.Bar lawyer",
            key: "zhanara_yerzhanova"
          },
          38: {
            name: "Askhat Kuanyshbayev, Zan.Bar lawyer",
            key: "askhat_kuanyshbayev"
          },
          37: {
            name: "Irina Kalinger, Zan.Bar lawyer",
            key: "irina_kalinger"
          },
          36: {
            name: "Asem Tusupova, Zan.Bar lawyer",
            key: "asem_tusupova"
          },
          35: {
            name: "Zhanara Yerzhanova, Zan.Bar lawyer",
            key: "zhanara_yerzhanova"
          }
        },
        ru: {
          34: {
            name: "Аубакиров Кенбай, юрист Zan.Bar",
            key: "kenbay_aubakirov"
          },
          33: {
            name: "Тусупова Асем, юрист Zan.Bar",
            key: "asem_tusupova"
          },
          32: {
            name: "Ержанова Жанара Жумагалиевна, юрист Zan.Bar",
            key: "zhanara_yerzhanova"
          },
          31: {
            name: "Ержанова Жанара Жумагалиевна, юрист Zan.Bar",
            key: "zhanara_yerzhanova"
          },
          30: {
            name: "Куанышбаев Асхат, юрист Zan.Bar",
            key: "askhat_kuanyshbayev"
          },
          29: {
            name: "Ержанова Жанара Жумагалиевна, юрист Zan.Bar",
            key: "zhanara_yerzhanova"
          },
          28: {
            name: "Калингер Ирина Владимировна, юрист Zan.Bar",
            key: "irina_kalinger"
          }
        }
      },
      categories: {
        en: {
          41: [{ name: "Civil law" }],
          40: [{ name: "Civil law" }],
          39: [{ name: "Civil law" }],
          38: [{ name: "Administrative law" }],
          37: [{ name: "Civil law" }],
          36: [{ name: "Civil law" }],
          35: [{ name: "Civil law" }]
        },
        ru: {
          26: [{ name: "Гражданское право" }],
          34: [{ name: "Гражданское право" }],
          33: [{ name: "Гражданское право" }],
          32: [{ name: "Гражданское право" }],
          31: [{ name: "Гражданское право" }],
          30: [{ name: "Административное право" }],
          29: [{ name: "Гражданское право" }],
          28: [{ name: "Гражданское право" }]
        }
      }
    };
  },
  components: {
    articlesList,
    articlesListItem,
    listItemsPreloader
  },
  computed: {
    cLoadingState(){
      return this.$sf.get("LOADING_STATE_BLOG");
    },
    cSearchLayout() {
      return this.searchLayout;
    },
    cBlogList() {
      return this.$sf.get("BLOG_LIST") || {};
    }
  },
  async mounted() {
    // Установить поиск
    this.$parent.$parent.searchLayoutShow = true;
    await this.$sf.act("LOAD_BLOG_LIST", this.params);
  },
  // После смены страницы удалить поиск
  beforeDestroy() {
    this.$parent.$parent.searchLayoutShow = false;
  }
};
</script>

<style scoped lang="scss"></style>
