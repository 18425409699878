import {
  BLOG_LIST,
  LOADING_STATE_BLOG
} from "@/store/modules/articles/list/_list.const";

export default {
  getters: {
    [BLOG_LIST]: state => state.blog.list,
    [LOADING_STATE_BLOG]: state => state.blog.loading
  }
};
