const modulesFiles = require.context("./localize/", true, /\.js$/);
// eslint-disable-next-line no-unused-vars
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.split("/");
  const value = modulesFiles(modulePath);
  if (modules[moduleName[1]]) {
    modules[moduleName[1]] = Object.assign(modules[moduleName[1]], {
      ...value.default
    });
  } else {
    modules[moduleName[1]] = { ...value.default };
  }
  return modules;
}, {});

export default {
  ru: {
    name: "рус",
    messages: modules.ru
  },
  en: {
    name: "eng",
    messages: modules.en
  }
};
