import simplePanelLayout from "@/containers/simple-panel/simplePanelLayout";

import Profile from "@/views/pages/admin/Profile";
import Applications from "@/views/pages/admin/Applications";

export default {
  path: "/panel",
  component: simplePanelLayout,
  meta: { title: "APanel", dntShow: true, auth: true },
  redirect: "/panel/user",
  children: [
    {
      path: "applications",
      name: "applications",
      component: Applications,
      meta: { title: "applications", auth: true }
    },
    {
      path: "user",
      name: "user",
      component: Profile,
      meta: { title: "my_profile", auth: true }
    }
  ]
};
