import mutations from "@/store/modules/articles/base/_base.mutations";
import states from "@/store/modules/articles/base/_base.states";
import getters from "@/store/modules/articles/base/_base.getters";
import actions from "@/store/modules/articles/base/_base.actions";

export default {
  ...states,
  ...getters,
  ...mutations,
  ...actions
};
