<template>
  <div class="user-photo">
    <img src="" alt="" />
  </div>
</template>


<style scoped lang="scss">
.user-photo {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: var(--th-blue-dark);
}
</style>

<script>
export default {
  name: "UserPhoto",
  props: {},
};
</script>