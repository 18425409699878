// mutations
export const SET_ACTIVE_ARTICLE_ID = "_detail.mutations/SET_ACTIVE_ARTICLE_ID";
export const SET_ACTIVE_ARTICLE_CONTENT =
  "_detail.mutations/SET_ACTIVE_ARTICLE_CONTENT";
// actions
export const LOAD_BLOG_DETAIL = "_detail.mutations/LOAD_BLOG_DETAIL";
export const SAVE_BLOG_ARTICLE = "_detail.mutations/SAVE_BLOG_ARTICLE";
// getters
export const BLOG_DETAIL = "_detail.getters/BLOG_DETAIL";

export default {
  SET_ACTIVE_ARTICLE_ID,
  SET_ACTIVE_ARTICLE_CONTENT,
  LOAD_BLOG_DETAIL,
  SAVE_BLOG_ARTICLE,
  BLOG_DETAIL
};
