import mutations from "@/store/modules/articles/detail/_detail.mutations";
import states from "@/store/modules/articles/detail/_detail.states";
import getters from "@/store/modules/articles/detail/_detail.getters";
import actions from "@/store/modules/articles/detail/_detail.actions";

export default {
  ...states,
  ...getters,
  ...mutations,
  ...actions
};
