<template>
  <footer class="mt-auto">
    <div class="footer-wrap">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <localized-link class="footer-logotype" to="/">
              <AIcon
                name="whiteLogotype"
                width="243"
                height="78"
                icon-stroke="none"
              />
            </localized-link>
          </div>
          <div class="col-md-8">
            <div
              class="d-flex footer-info-data align-items-start space-between w-100"
            >
              <div class="flex-grow-1">
                <div class="caption">{{ $t("navigation") }}</div>
                <nav class="footer__nav">
                  <localized-link to="/">{{ $t("main") }}</localized-link>
                  <localized-link to="/company">{{ $t("about_company") }}</localized-link>
                  <localized-link to="/faq">{{ $t("faq") }}</localized-link>
                  <localized-link to="/blog">{{ $t("blog") }}</localized-link>
                </nav>
              </div>
              <div class="flex-grow-1">
                <div class="caption">{{ $t("contacts") }}</div>
                <div class="item d-flex align-items-center">
                  <AIcon
                    icon-stroke="none"
                    icon-fill="var(--th-white)"
                    width="20"
                    height="21"
                    name="mail"
                  />
                  <a href="mailto:info@zan.bar">info@zan.bar</a>
                </div>
                <div class="item d-flex align-items-center">
                  <AIcon
                    icon-stroke="#fff"
                    width="20"
                    height="21"
                    name="phone"
                  />
                  <a href="tel:+77074017700">+7 (707) 401-77-00</a>
                </div>
                <!--                <div class="item d-flex align-items-center">-->
                <!--                  <a style="margin-left: 35px;" href="tel:8 (707) 401-77-00">-->
                <!--                    8 (707) 401-77-00-->
                <!--                  </a>-->
                <!--                </div>-->
              </div>
              <div class="flex-grow-1">
                <div class="caption">{{ $t("social") }}</div>
                <div class="footer-social_block">
                  <a href="https://www.facebook.com/zanbar.kz" target="_blank">
                    <AIcon
                      icon-stroke="none"
                      width="35"
                      height="35"
                      name="facebook"
                    />
                  </a>
                  <a href="https://instagram.com/zan__bar?igshid=qhjg1qfeactv" target="_blank">
                    <AIcon
                      icon-stroke="none"
                      width="35"
                      height="35"
                      name="instagram"
                    />
                  </a>
<!--                  <a href="" target="_blank">-->
<!--                    <AIcon-->
<!--                      icon-stroke="none"-->
<!--                      width="35"-->
<!--                      height="35"-->
<!--                      name="twitter"-->
<!--                    />-->
<!--                  </a>-->
                  <a href="https://wa.me/77074017700" target="_blank">
                    <AIcon
                      icon-stroke="none"
                      width="35"
                      height="35"
                      name="whatsapp"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <div class="a d-flex align-items-center">
            <p class="ml-auto paraq-italic">
              {{ $t("footer_app_text") }}
            </p>
            <a href="">
              <AIcon
                width="35"
                height="35"
                icon-stroke="#fff"
                name="apple-store-stroke"
              />
            </a>
            <a href="">
              <AIcon
                width="35"
                height="35"
                icon-stroke="#fff"
                name="android-store-stroke"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "commonFooter"
};
</script>

<style scoped lang="scss">
footer {
  display: flex;
  align-items: center;

  * {
    color: var(--th-white);
  }

  .footer__nav {
    display: flex;
    flex-direction: column;

    a {
      text-transform: uppercase;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .caption {
    color: var(--th-white);
    margin-bottom: 25px;
  }

  .footer-logotype {
    display: block;
    margin-top: -23px;
  }

  .footer-wrap {
    margin-top: 70px;
    background: var(--th-blue-dark);
    padding-top: 53px;
    width: 100%;
  }

  .footer-social_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-info-data {
    .item {
      margin-right: 40px;
      margin-bottom: 15px;

      a {
        color: #fff;
      }

      svg {
        margin-right: 15px;
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid var(--th-blue);
    padding: 25px 0;
    margin-top: 30px;
    .a {
      p.paraq-italic {
        font-style: italic;
        font-weight: 300;
        color: var(--th-white);
      }
      & > * {
        margin-bottom: 0;
        margin-right: 30px;
        display: block;
      }
    }
  }
}
</style>
