// actions
export const LOAD_APPLICATIONS = "_applications.actions/LOAD_APPLICATIONS";

// mutations
export const SET_APPLICATIONS_LIST =
  "_applications.mutations/SET_APPLICATIONS_LIST";

// getter
export const APPLICATIONS_LIST = "_applications.getters/APPLICATIONS_LIST";

export default {
  APPLICATIONS_LIST,
  SET_APPLICATIONS_LIST,
  LOAD_APPLICATIONS
};
