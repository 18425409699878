export default {
  default_title_page_t:
    "ZanBar - send your application to the best lawyers in the city!",
  home_page_main_title:
    "<span>Leave a request</span> and get an offer with prices, reviews and ratings from the best lawyers in your city",
  // homepage two section
  home_page_main_title_two_section: "%{b} in numbers",
  home_page_scanQr: "Scan the QR code <br> and download the mobile app",
  how_it_works: "How it works?",
  add_application: "Leave a request<br> at Zan.Bar",
  receive_offers: "Receive offers <br>from lawyers in your city",
  reviews: "Responses",
  main: "Main",
  about_company: "About company",
  blog: "Blog",
  faq: "FAQ",
  recently_added_articles: "Recently added articles",
  lawyers_work_with_us: "1300 lawyers work with us",
  my_profile: "My profile",
  articles: "Articles",
  editing_pages: "Editing pages",
  settings: "Settings",
  // для админки
  name_or_company_name: "Name or company name",
  number_phone: "Number phone",
  city: "City",
  to_create_an_application_you_need_to_login: "To create an application you need to log in ",
  we_sent_an_sms_with_a_code_to_the_number: "We sent an SMS with a code to the number",
  to_get_the_code: "To get the code",
  we_sent_sms: "We sent an SMS with a code to the number",
  application_is_accepted: "Thank! Your<br />  application is accepted",
  mobile_version_of_the_application:
    "For your convenience, download the mobile version of the application",
  we_are_glad_to_help: "We are glad to help you",
  code_from_sms: "Enter the code from SMS",
  incorrect_description_format: "Incorrect description format, the message must be at least 20 characters",
};
