// eslint-disable-next-line no-unused-vars
import Store from "@/store";
import StoreConst from "./store.consts";

class StoreFacade {
  static get(name, arg = false) {
    let getter = Store.getters[StoreConst[name]];
    if (getter && arg) return getter(arg);
    else return getter;
  }
  static set(name, payload) {
    return Store.commit(StoreConst[name], payload);
  }
  static act(name, payload) {
    return Store.dispatch(StoreConst[name], payload);
  }
}

export default StoreFacade;
