import {
  SET_ACTIVE_ARTICLE_ID,
  SET_ACTIVE_ARTICLE_CONTENT,
  LOAD_BLOG_DETAIL,
  SAVE_BLOG_ARTICLE
} from "@/store/modules/articles/detail/_detail.const";
import {
  SaveActiveArticle,
  LoadActiveArticle
} from "@/api/articles/_detail.articles.api";

export default {
  actions: {
    async [LOAD_BLOG_DETAIL]({ commit, dispatch }, payload) {
      payload.retry_action = () => {
        dispatch(LOAD_BLOG_DETAIL, payload);
      };
      return await LoadActiveArticle(payload).then(
        resp => {
          commit(SET_ACTIVE_ARTICLE_ID, resp.id);
          commit(SET_ACTIVE_ARTICLE_CONTENT, resp);
          return resp;
        },
        () => {
          commit(SET_ACTIVE_ARTICLE_ID, null);
          commit(SET_ACTIVE_ARTICLE_CONTENT, {});
        }
      );
    },
    ClearDetailArticle({ commit }) {
      commit(SET_ACTIVE_ARTICLE_ID, null);
      commit(SET_ACTIVE_ARTICLE_CONTENT, {});
    },
    // eslint-disable-next-line no-unused-vars
    async [SAVE_BLOG_ARTICLE]({ commit }, payload) {
      await SaveActiveArticle(payload).then(
        resp => console.log(resp),
        err => console.log(err)
      );
    }
  }
};
