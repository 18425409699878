// eslint-disable-next-line no-unused-vars
import {
  CATALOG,
  CATALOG_AC,
  GET_CATALOG
} from "@/store/modules/sys/catalog/_catalog.const";

export default {
  getters: {
    [CATALOG](state) {
      return state.catalog?.data;
    },
    // eslint-disable-next-line no-unused-vars
    [CATALOG_AC]: (state, getters) => key => {
      key = GET_CATALOG[key] || false;
      let catalog = getters[CATALOG];
      if (key) return catalog?.genKeys(key);
      return key;
    }
  }
};
