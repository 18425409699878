<template>
  <ALink class="applications-item">
    <div class="d-flex align-items-center head">
      <div class="applications-item__category-name">
        <h4 class="bold">{{ cCategoryName }}</h4>
      </div>
      <div class="applications-item__status"></div>
    </div>
    <p class="paraq-1">{{ cData.note }}</p>
  </ALink>
</template>

<script>
export default {
  name: "applications-item",
  props: {
    data: Object
  },
  computed: {
    cData() {
      return this.data;
    },
    cCategoryName() {
      return this.cData.cCategory.find(
        ({ id }) => id === this.cData.ad_category_id
      ).name;
    }
  }
};
</script>

<style scoped lang="scss">
.applications-item {
  width: 100%;
  max-width: 458px;
  margin: 15px;
  padding: 26px;
  background: var(--th-white);
  box-shadow: 1px 8px 80px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  text-decoration: none;
  position: relative;
  &:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 64.22%);
    content: "";
    height: 20px;
    width: calc(100% - 26px * 2);
    position: absolute;
    bottom: 40px;
  }
  .head{
    margin-bottom: 20px;
  }
}
</style>
