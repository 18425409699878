import http from "@/utils/http";
export const LoadBlogList = async (params) => {
  let retry_action = params.retry_action;
  delete params.retry_action;
  return await http({
    url: `/api/blogs`,
    params,
    retry_action
  }).then(
    res => {
      return res.data;
    },
    error => {
      throw error;
    }
  )
};
